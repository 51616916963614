import React, { useCallback, useEffect, useState } from "react";
import { format, differenceInDays, parseISO } from "date-fns";
import { useHistory } from "react-router-dom";
import { upperFirst } from "lodash";
import { connect, useDispatch } from "react-redux";
import { callApi } from "../../utils/ContentoApi";
import { getChargebeeInstance } from "../../utils/Chargebee";
import * as accountActions from "../../state/actions/AccountActions";
import * as modalActions from "../../state/actions/ModalActions";
import { useToaster } from "@hellocontento/maillard";
import { saveAs } from "file-saver";
import { createChargeBeePortal } from "../../state/actions/AccountActions";
import { formatDate } from "utils/date";

import { CreditCard } from "styled-icons/boxicons-solid";
import { Box, Flex, Text } from "rebass/styled-components";
import ReactLoading from "react-loading";
import Button from "../common/Button";
import Price from "../common/Price";
import Media from "../common/Media";
import AlertMessage from "../common/AlertMessage";
import {
  SettingsTitle,
  SettingsHeader,
  SettingsSubtitle,
  SettingsSection,
  SettingsWell,
  SettingsBrick,
  InvoiceLine,
  SettingsDescription,
  InvoiceDescription,
  InvoiceHeading,
  InvoicePrice,
  DownloadButton,
  InvoiceStatus,
  TrailDescription,
  ButtonIcon
} from "./styles";

import { NativeLink } from "components/common/Link";
import {
  planMapping,
  coachingAddOnMapping,
  plans,
  telenetPlans,
  telenetCoachingAddonMapping,
  telenetPlanMapping
} from "../../constants";

import billingAddonIcon from "../../assets/images/billing-plan-addon@2x.png";

import visaIcon from "../../assets/images/billing-card-visa.png";
import masterCardIcon from "../../assets/images/billing-card-mastercard.png";
import otherCardIcon from "../../assets/images/billing-card-other.png";
import BillingUsage from "./BillingUsage";
import { trackAnalyticsEvent } from "../../state/actions/AnalyticsActions";
import { fetchAccount } from "../../state/actions/AccountActions";
import { useContentoApi } from "../../utils/useContentoApi";

import EmptyCard from "./EmptyCard";
import { handleOpenChat } from "utils/AppActions";

const CHARGEBEE_INVOICE_STATUS = {
  paid: "paid",
  notPaid: "not_paid",
  pending: "pending",
  paymentDue: "payment_due"
};

function Billing({ account }) {
  const isTelenetCustomer = process.env.REACT_APP_CLIENT === "telenet";
  const history = useHistory();
  const addToast = useToaster();
  const [billingInfo, setBillingInfo] = useState(null);
  const dispatch = useDispatch();
  const [isDownloadingAll, setIsDownloadingAll] = useState(false);

  const [paymentSucceeded] = useContentoApi(
    `/accounts/${account.id}/payment-succeeded`
  );

  const cbInstance = getChargebeeInstance();

  const refreshBillingInfo = useCallback(() => {
    async function fetchBillingInfo() {
      try {
        const billingInfo = await callApi({
          method: "get",
          url: `/accounts/${account.id}/billing`
        });

        setBillingInfo(billingInfo);
      } catch (error) {
        addToast(
          "We could not retrieve the billing information. Please try again later or contact support.",
          "error"
        );
        setBillingInfo(null);
      }
    }
    fetchBillingInfo();
  }, [account.id, addToast]);

  useEffect(() => {
    refreshBillingInfo();
  }, [account.id, refreshBillingInfo]);

  const downloadInvoice = invoiceId => {
    callApi({
      method: "get",
      url: `/billing/invoices/${invoiceId}/pdf`
    })
      .then(pdfInfo => {
        window.location.href = `${pdfInfo.download_url}`;
      })
      .catch(() => {
        addToast(
          "It wasn't possible to download the invoice. Please try again later or contact support.",
          "error"
        );
      });
  };

  const downloadInvoices = () => {
    setIsDownloadingAll(true);
    callApi({
      method: "get",
      url: `/accounts/${account.id}/billing/invoices/zip`,
      responseType: "arraybuffer"
    })
      .then(file => {
        const blob = new Blob([file], { type: "application/zip" });

        const filename = "invoices.zip";
        saveAs(blob, filename);
        setIsDownloadingAll(false);
      })
      .catch(e => {
        setIsDownloadingAll(false);
        addToast(
          "It wasn't possible to download the invoices. Please try again later or contact support.",
          "error"
        );
      });
  };

  const updatePaymentDetails = () => {
    cbInstance.openCheckout({
      hostedPage: () => {
        return callApi({
          method: "put",
          url: `/accounts/${account.id}/payment`
        }).then(response => ({ ...response.hosted_page }));
      },
      error(error) {
        console.error(error);
      },
      success() {
        refreshBillingInfo();
        addToast("Successfully updated card!", "success");
        dispatch(trackAnalyticsEvent("Added Payment Card"));
      },
      close: () => {},
      step() {}
    });
  };

  const handleUpgrade = (type = "plan") => {
    const updates = {};
    const isTelenetCustomer = process.env.REACT_APP_CLIENT === "telenet";

    const period = `${billingInfo.subscription.billing_period_unit.toUpperCase()}LY`;
    if (type === "plan") {
      updates.plan = isTelenetCustomer
        ? telenetPlanMapping["EUR"]["YEARLY"]
        : planMapping[billingInfo.subscription.currency_code][period][
            "SMALL_BIZ"
          ];
    } else if (type === "coaching") {
      updates.addon =
        coachingAddOnMapping[billingInfo.subscription.currency_code][period];
    }

    cbInstance.openCheckout({
      hostedPage: () => {
        return callApi({
          method: "put",
          url: `/accounts/${account.id}/billing/upgrade`,
          data: updates
        }).then(response => ({ ...response.hosted_page }));
      },
      error(error) {
        console.error(error);
      },
      loaded() {
        dispatch(trackAnalyticsEvent("Upgrade Started"));
      },
      success() {
        dispatch(trackAnalyticsEvent("Upgrade Completed"));
        paymentSucceeded()
          .then(() => {
            cbInstance.closeAll();
            dispatch(fetchAccount(account.id));
            refreshBillingInfo();
            addToast("Successfully upgraded your account!", "success");
          })
          .catch(() => {
            addToast(
              "Something went wrong upgrading. Please contact support.",
              "error"
            );
          });
      },
      close: () => {},
      step() {}
    });
  };

  const plan = account.billing?.willowPlan
    ? account.isTelenetCustomer
      ? Object.values(telenetPlans).find(
          p => p.id.toLowerCase() === account.billing?.willowPlan?.toLowerCase()
        )
      : plans[account.billing.willowPlan]
    : "";

  if (!billingInfo) {
    return <ReactLoading color="#bbb" type="cylon" />;
  }

  const getCardDescription = card => {
    if (!card)
      return [
        "No credit card on file.",
        "You will receive an invoice with payment details."
      ];
    const cardInfo = `Ends in ${card.last4}, expires on ${card.expiry_month}/${card.expiry_year}`;

    return [cardInfo];
  };

  const getCardIcon = card => {
    if (card && card.card_type === "visa") return visaIcon;
    else if (card && card.card_type === "mastercard") return masterCardIcon;
    else return otherCardIcon;
  };

  const handleCancelAccount = () => {
    history.push(`/accounts/${account.id}/close`);
  };

  const getInvoicePlan = lineItems => {
    const plan = lineItems.find(item => item.entity_type.includes("plan"));

    if (!plan) return null;

    const [company, planType] = plan.description.split("-");

    return (
      <>
        <InvoiceDescription>{company}</InvoiceDescription>
        <InvoiceDescription subTitle={true}>{planType}</InvoiceDescription>
      </>
    );
  };

  return (
    <Box mb={48}>
      <SettingsHeader>
        <SettingsTitle>Subscription</SettingsTitle>
        {account.billing && account.billing.billingStatus !== "in_trial" && (
          <SettingsDescription>
            Here you can find your invoices, manage your subscription plan and
            change your payment method.
          </SettingsDescription>
        )}
        {account.billing && account.billing.billingStatus === "in_trial" && (
          <TrailDescription size="lg" variant="danger">
            Your free Willow Trial will expire in{" "}
            <strong>
              {`${differenceInDays(
                parseISO(account.billing.trialEnd),
                new Date()
              )} days`}
            </strong>
          </TrailDescription>
        )}
      </SettingsHeader>
      <SettingsSection>
        <SettingsSubtitle>Plan &amp; Billing</SettingsSubtitle>

        {account.billing && account.billing.billingStatus === "in_trial" && (
          <AlertMessage variant="info" title="Pay for Willow" icon={CreditCard}>
            <p>
              Willow is the Social Media solution for professional services, B2B
              businesses and personal brands. With Willow you never run out of
              content.
            </p>
            <p>
              <ul>
                <li>
                  <strong>Content calendar</strong> for the next 365 days
                </li>
                {!isTelenetCustomer && (
                  <li>Follow industry news &amp; thought leaders</li>
                )}
                <li>
                  <strong>Analytics</strong> that drive actions
                </li>
                <li>Simple and effective framework</li>
              </ul>
            </p>
            <Button
              size="sm"
              variant="primary"
              onClick={() => {
                window.open(
                  "https://meetings.hubspot.com/brent-schepers",
                  "_blank"
                );
              }}
            >
              Speak to Sales
            </Button>
          </AlertMessage>
        )}
        {account.billing &&
          account.billing.billingStatus !== "in_trial" &&
          plan && (
            <SettingsWell>
              <Media
                title={`Willow ${plan.title}`}
                thumbnail={plan.icon}
                description={[
                  `Paid ${account.billing.billingPeriodUnit}ly`,
                  account.billing.nextBillingAt
                    ? `Renews on ${format(
                        new Date(account.billing.nextBillingAt),
                        "LLLL d, yyyy"
                      )}`
                    : `Does not renew`
                ]}
                buttonVariant="primary"
                buttonLabel={
                  (isTelenetCustomer &&
                    Object.entries(telenetPlanMapping["EUR"]).find(
                      ([k, p]) =>
                        p.toLowerCase() === plan.id.toLowerCase() &&
                        k === "MONTHLY"
                    )) ||
                  plan.id === "PERSONAL_BRANDING"
                    ? "Upgrade Plan"
                    : null
                }
                buttonAction={
                  (isTelenetCustomer &&
                    Object.entries(telenetPlanMapping["EUR"]).find(
                      ([k, p]) =>
                        p.toLowerCase() === plan.id.toLowerCase() &&
                        k === "MONTHLY"
                    )) ||
                  plan.id === "PERSONAL_BRANDING"
                    ? () => handleUpgrade()
                    : null
                }
              />
            </SettingsWell>
          )}
        {!account.isTelenetCustomer &&
          account.billing &&
          account.billing.billingStatus !== "in_trial" &&
          account.billing.willowCoachingPlan && (
            <SettingsWell>
              <Media
                thumbnail={billingAddonIcon}
                title="Willow Coaching"
                description={[
                  `Monthly plan, paid ${account.billing.billingPeriodUnit}ly`,
                  account.billing.nextBillingAt
                    ? `Renews on ${format(
                        new Date(account.billing.nextBillingAt),
                        "LLLL d, yyyy"
                      )}`
                    : `Does not renew`
                ]}
              />
            </SettingsWell>
          )}
        {!account.isTelenetCustomer &&
          account.billing &&
          account.billing.billingStatus !== "in_trial" &&
          !account.billing.willowCoachingPlan && (
            <SettingsWell>
              <Media
                thumbnail={billingAddonIcon}
                title="Willow Coaching"
                description={["You don't have a coaching plan right now."]}
                buttonVariant="primary"
                buttonLabel={"Add Coaching"}
                buttonAction={() => handleUpgrade("coaching")}
              />
            </SettingsWell>
          )}
        {billingInfo?.customer?.card ? (
          <SettingsWell>
            <Media
              title={upperFirst(billingInfo?.customer?.card.card_type)}
              thumbnail={getCardIcon(billingInfo?.customer?.card)}
              description={getCardDescription(billingInfo?.customer?.card)}
              buttonLabel="Update Card"
              buttonVariant="primaryLite"
              buttonAction={() => updatePaymentDetails()}
            />
          </SettingsWell>
        ) : (
          account.billing?.billingStatus !== "in_trial" && (
            <SettingsWell>
              <Media
                title="Pay by card"
                thumbnail={getCardIcon()}
                description="We don't have a credit card on file."
                buttonLabel="Add Card"
                buttonVariant="primaryLite"
                buttonAction={() => updatePaymentDetails()}
              />
            </SettingsWell>
          )
        )}
      </SettingsSection>
      {!isTelenetCustomer && (
        <BillingUsage account={account} onUpgrade={handleUpgrade} />
      )}

      {billingInfo.invoices &&
        account.billing?.billingStatus !== "in_trial" && (
          <SettingsSection>
            <SettingsSubtitle>
              Invoices
              {billingInfo.invoices.length > 0 && (
                <Button
                  variant="primary"
                  onClick={() => {
                    if (!isDownloadingAll) downloadInvoices();
                  }}
                >
                  <Flex display="inline-flex" alignItems="center">
                    {isDownloadingAll ? (
                      <ReactLoading type="cylon" height="24px" width="24px" />
                    ) : (
                      <ButtonIcon className="icon-download-lg"></ButtonIcon>
                    )}
                    Download all
                  </Flex>
                </Button>
              )}
            </SettingsSubtitle>
            {billingInfo.invoices?.length === 0 ? (
              <EmptyCard
                title="No invoices found"
                message="We couldn’t find any invoices for this account. If you were expecting to find invoices, please contact support."
                support="Contact support"
                action={handleOpenChat}
              />
            ) : (
              <>
                <InvoiceLine>
                  <Flex flex={1}>
                    <InvoiceHeading>Date</InvoiceHeading>
                  </Flex>
                  <Flex flex={2}>
                    <InvoiceHeading>Description</InvoiceHeading>
                  </Flex>
                  <Flex flex={1}>
                    <InvoiceHeading>Invoice Total</InvoiceHeading>
                  </Flex>
                  <Flex flex={1}>
                    <InvoiceHeading>Status</InvoiceHeading>
                  </Flex>
                </InvoiceLine>
                {billingInfo.invoices.map(invoice => (
                  <InvoiceLine key={invoice.id}>
                    <Flex flex={1}>{formatDate(invoice.date)}</Flex>
                    <Flex flex={2} flexDirection="column">
                      {getInvoicePlan(invoice.line_items)}
                    </Flex>
                    <Flex flex={1}>
                      <InvoicePrice
                        variant={
                          invoice.status === CHARGEBEE_INVOICE_STATUS.paymentDue
                            ? "danger"
                            : null
                        }
                      >
                        <Price
                          price={invoice.total / 100}
                          currency={invoice.currency_code}
                        />
                      </InvoicePrice>
                    </Flex>
                    <Flex flex={1} flexDirection="column" alignItems="left">
                      {invoice.status ===
                      CHARGEBEE_INVOICE_STATUS.paymentDue ? (
                        <InvoiceStatus status="danger">
                          {invoice.status}
                        </InvoiceStatus>
                      ) : (
                        <>
                          <InvoiceStatus>{invoice.status}</InvoiceStatus>
                          <span>
                            <DownloadButton
                              onClick={() => downloadInvoice(invoice.id)}
                            >
                              Download PDF
                            </DownloadButton>
                          </span>
                        </>
                      )}
                    </Flex>
                  </InvoiceLine>
                ))}
              </>
            )}
          </SettingsSection>
        )}
      {account.billing &&
        ["active", "in_trial"].includes(account.billing.billingStatus) && (
          <SettingsSection>
            <SettingsSubtitle>Cancellation</SettingsSubtitle>
            {account?.billing?.billingStatus === "active" ? (
              <SettingsBrick>
                <Text fontWeight={"700"} fontSize={"16px"} mb={8}>
                  Facing problems?
                </Text>
                <Text lineHeight={1.5} mb={32}>
                  We’re always here to help. There’s a couple of ways to find an
                  answer to your question.
                  <Flex flexDirection="column" marginTop={"20px"}>
                    <span>
                      <NativeLink
                        underline
                        href="https://support.willow.co/knowledge"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Go to the Help Center &#8594;
                      </NativeLink>
                    </span>
                    <span>
                      <NativeLink
                        underline
                        href="#openchat"
                        onClick={e => {
                          e.preventDefault();
                          if (window.HubSpotConversations) {
                            window.HubSpotConversations.widget.open();
                          }
                        }}
                      >
                        {`Chat with ${
                          account.accountManagerData
                            ? account.accountManagerData.firstName
                            : "your expert"
                        }`}{" "}
                        &#8594;
                      </NativeLink>
                    </span>
                  </Flex>
                </Text>
                <Text fontWeight={"700"} fontSize={"16px"} mb={8}>
                  Want to cancel your subscription?
                </Text>
                <Text lineHeight={1.5}>
                  You can request a cancellation of your subscription through
                  the chat in the bottom left of your Willow Home.{" "}
                  {account.accountManagerData
                    ? account.accountManagerData.firstName
                    : "Steven"}{" "}
                  will then help you through the process.
                  <Flex flexDirection="column" marginTop={"20px"}>
                    <span>
                      <NativeLink
                        underline
                        href="#openchat"
                        onClick={e => {
                          e.preventDefault();
                          if (window.HubSpotConversations) {
                            window.HubSpotConversations.widget.open();
                          }
                        }}
                      >
                        Request cancellation &#8594;
                      </NativeLink>
                    </span>
                  </Flex>
                </Text>
              </SettingsBrick>
            ) : (
              <SettingsBrick>
                <Text lineHeight={1.5} fontWeight="bold" marginBottom={12}>
                  Cancel my account
                </Text>
                <Text lineHeight={1.5}>
                  Want to cancel your account before your trial ends? Your data
                  will be permanently deleted from our servers within 30 days
                  and from all backups within 60 days.
                </Text>
                <Box marginTop={24}>
                  <Button variant="danger" onClick={handleCancelAccount}>
                    Cancel my account
                  </Button>
                </Box>
              </SettingsBrick>
            )}
          </SettingsSection>
        )}
    </Box>
  );
}

const mapStateToProps = state => {
  return {
    account: state.account.data
  };
};

export default connect(mapStateToProps, {
  updateAccount: accountActions.updateAccount,
  openModal: modalActions.openModal,
  createChargeBeePortal: accountActions.createChargeBeePortal
})(Billing);

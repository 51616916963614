import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import * as storageService from "utils/storage";

import { PanelWrapper, AccordionContent } from "../styles";

import PanelItem from "./panel-item";
import PanelTitle from "./panel-title";
import ShowMore from "./panel-item/ShowMore";
import { ITopic, ISource } from "services/news";
import EmptyCard from "components/settings/EmptyCard";
import { SOURCE_TYPE } from "services/news.types";

interface IPanel {
  id: string;
  to: string;
  title: string;
  label: string;
  icon: string;
  activeIcon: string;
  emptyState: {
    title: string;
    message: string;
    support: string;
    action: () => void;
  };
  contextMenu: Array<any>;
}

interface IPanelProps {
  match: any;
  type: string;
  panel: IPanel;
  reorder: any;
  isCollapsed: boolean;
  dataSet: Array<ITopic | ISource>;
}

type PanelSource = ISource | ITopic;

const INITIAL_LIMIT = 5;
const KEY_SECTIONS_ITEM_VISIBILITY = "sections_item_visibility";

interface LooseObject {
  [key: string]: boolean;
}

const Panel: React.FC<IPanelProps> = ({
  type,
  panel,
  match,
  dataSet,
  reorder,
  isCollapsed
}) => {
  const showMoreStat: LooseObject = JSON.parse(
    JSON.stringify(storageService.get(KEY_SECTIONS_ITEM_VISIBILITY)) ?? {}
  );

  const history = useHistory();
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const account = useSelector((state: any) => state.account.data);

  const [showAll, setShowAll] = useState<boolean>(
    showMoreStat && showMoreStat[type]
  );

  const toggleShowAll = () => {
    setShowAll(!showAll);
    storageService.set(KEY_SECTIONS_ITEM_VISIBILITY, {
      ...showMoreStat,
      [type]: !showAll
    });
  };

  const onAddClick = () => {
    history.push(`/accounts/${account.id}/content/explore`);
  };

  return (
    <PanelWrapper>
      <PanelTitle
        match={match}
        to={panel.to}
        title={panel.title}
        label={panel.label}
        icon={{
          default: panel.icon,
          active: panel.activeIcon
        }}
        isExpanded={isExpanded}
        onAddClick={onAddClick}
        collapsed={isCollapsed}
        expand={() => {
          if (dataSet.length < 1) return;
          setIsExpanded(!isExpanded);
        }}
        contextMenu={panel.contextMenu}
      />
      <AccordionContent open={isExpanded}>
        {dataSet.length < 1 ? (
          !isCollapsed && (
            <EmptyCard
              noBgStyle={true}
              py={20}
              width={75}
              {...panel.emptyState}
            />
          )
        ) : (
          <>
            <DragDropContext onDragEnd={reorder}>
              <Droppable droppableId={type}>
                {(provided): any => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {dataSet
                      .slice(0, showAll ? dataSet.length : INITIAL_LIMIT)
                      .map((data: PanelSource, index: number) => {
                        let link = "";
                        if (panel.id === SOURCE_TYPE.TOPICS) {
                          link = `${match.url}/${panel.id}/${data.id}${
                            "language" in data ? `/${data.language}` : ""
                          }`;
                        } else {
                          let type = "domains";

                          if ("type" in data) {
                            type =
                              data.type === SOURCE_TYPE.TWITTER
                                ? "influencers"
                                : data.type;

                            if (data.type === "html-links") {
                              type = "domains";
                            }
                          }
                          link = `${match.url}/${type}/${data.id}`;
                        }

                        return (
                          <Draggable
                            draggableId={data.id}
                            index={index}
                            key={data.id}
                          >
                            {provided => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <PanelItem
                                  to={link}
                                  type={type}
                                  item={data}
                                  borderStyle={
                                    type === SOURCE_TYPE.TOPICS
                                      ? "square"
                                      : "circle"
                                  }
                                  isCollapsed={isCollapsed}
                                />
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {dataSet.length > INITIAL_LIMIT && (
              <ShowMore
                id={type}
                info={type}
                showAll={showAll}
                collapsed={isCollapsed}
                onClick={toggleShowAll}
                remainingCount={dataSet.length - INITIAL_LIMIT}
              />
            )}
          </>
        )}
      </AccordionContent>
    </PanelWrapper>
  );
};

export default Panel;

import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback
} from "react";
import { connect } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useToaster } from "@hellocontento/maillard";
import { startOfDay, startOfMonth, endOfMonth, format } from "date-fns";

import MonthGrid from "./MonthGrid";
import TaskPanel from "../tasks/TaskPanel";
import ScheduleHeader from "../ScheduleHeader";
import { useAppActions } from "contextApi/appContext";
import { useContentoApi } from "utils/useContentoApi";
import { useQueryParams } from "utils/useQueryParams";
import { useComposerState } from "contextApi/composerContext";
import { MonthLayout, MonthBody, MonthSidebar } from "./styles";
import * as activityActions from "state/actions/ActivityActions";
import { getDate, getFormattedDate, getOffsetDate } from "../utils/dateUtils";
import DragDropContextWrapper from "../common/drag-and-drop/DragDropContextWrapper";

function Month({ location, activities, modalState, saveActivities }) {
  const account = useSelector(state => state.account.data);
  const history = useHistory();
  const queryParams = useQueryParams();
  const setIsDraftPanelOpen = useAppActions(state => state.setIsDraftPanelOpen);
  const onPostedEvent = useComposerState(state => state.events.onPostedEvent);
  const onReloadEvent = useComposerState(state => state.events.onReloadEvent);
  const onDeletedEvent = useComposerState(state => state.events.onDeletedEvent);

  const today = useMemo(() => {
    return queryParams.date
      ? new Date(queryParams.date)
      : startOfDay(getDate({ timezone: account.timezone }));
  }, [account.timezone, queryParams.date]);

  const addToast = useToaster();

  const [currentDate, setCurrentDate] = useState(today);

  const [isLoading, setIsLoading] = useState(true);
  const [isPlannerMode, setPlannerMode] = useState(
    queryParams.taskId || queryParams.taskGroupId
  );

  const [fetchEntries] = useContentoApi(
    `/accounts/${account.id}/activities/calendar`
  );

  const handleChangeDate = useCallback(date => {
    setCurrentDate(date);
  }, []);

  const [selectedIds, setSelectedIds] = useState(
    account.channels.map(channel => channel.id)
  );

  const handleChannelChange = useCallback(data => {
    setSelectedIds(data);
  }, []);

  const handleCloseSidebar = useCallback(() => {
    setPlannerMode(false);
    if (location.search !== "") {
      history.push(`/accounts/${account.id}/schedule/month`);
    }
  }, [account.id, history, location.search]);

  useEffect(() => {
    if (queryParams.taskId || queryParams.taskGroupId) {
      setPlannerMode(true);
    }
  }, [queryParams.taskId, queryParams.taskGroupId]);

  const monthEndRef = useRef(null);

  const scrollToBottom = useCallback(() => {
    if (monthEndRef?.current) {
      monthEndRef.current.scrollIntoView({ behaviour: "smooth" });
    }
  }, []);

  useEffect(() => {
    const { post } = modalState?.modalProps ?? {};
    if (
      !!post &&
      modalState.isOpen &&
      modalState.modalType === "COMPOSER_MODAL"
    ) {
      const activeDate = post.postedAt || post.scheduledAt;

      if (!!activeDate) {
        setCurrentDate(new Date(activeDate));

        if (format(new Date(activeDate), "d") >= 15) {
          scrollToBottom();
        }
      }
    }
  }, [
    scrollToBottom,
    modalState.isOpen,
    modalState.modalProps,
    modalState.modalType
  ]);

  const refreshEntries = useCallback(async () => {
    const period = {
      start: getOffsetDate(startOfMonth(currentDate), { days: -7 }),
      end: getOffsetDate(endOfMonth(currentDate), { days: 7 })
    };
    setIsLoading(true);

    try {
      const params = {
        fromDate: getFormattedDate(period.start),
        toDate: getFormattedDate(period.end),
        timezone: account.timezone
      };

      if (selectedIds.length > 0) {
        params.channelIds = selectedIds.join(",");
      }
      const response = await fetchEntries({ params });

      const results = response.map(res => {
        return {
          ...res,
          isHidden: false
        };
      });

      saveActivities({ data: results, params });
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      addToast(err.message || "There was an error fetching slots", "error");
    }
  }, [
    addToast,
    selectedIds,
    currentDate,
    fetchEntries,
    saveActivities,
    account.timezone
  ]);

  useEffect(() => {
    if (queryParams.date && format(new Date(queryParams.date), "d") >= 15) {
      scrollToBottom();
    }
  }, [queryParams.date, isLoading, scrollToBottom]);

  useEffect(() => {
    refreshEntries();
  }, [refreshEntries]);

  const onDelete = useCallback(
    data => {
      if (!!data.taskId || !!data.taskGroupId) {
        handleCloseSidebar();
      }
      refreshEntries();
    },
    [refreshEntries, handleCloseSidebar]
  );

  const onPosted = useCallback(
    post => {
      const postData = !!post.posts ? post.posts[0] : post.post ?? post;

      if (!!postData) {
        const activeDate =
          postData.postedAt || postData.scheduledAt
            ? new Date(postData.postedAt || postData.scheduledAt)
            : undefined;

        if (!!activeDate) {
          handleChangeDate(activeDate);
          if (format(activeDate, "d") >= 15) {
            scrollToBottom();
          }
        }

        if (!activeDate && postData.isDraft) {
          setIsDraftPanelOpen(true);
        }
      } else {
        refreshEntries();
      }
    },
    [refreshEntries, handleChangeDate, scrollToBottom, setIsDraftPanelOpen]
  );

  useEffect(() => onPostedEvent.listen(onPosted), [onPostedEvent, onPosted]);

  useEffect(
    () => onReloadEvent.listen(refreshEntries),
    [onReloadEvent, refreshEntries]
  );

  useEffect(() => onDeletedEvent.listen(onDelete), [onDeletedEvent, onDelete]);

  return (
    <MonthLayout isSidebarVisible={isPlannerMode}>
      <MonthBody isSidebarVisible={isPlannerMode}>
        <ScheduleHeader
          isSidebarVisible={isPlannerMode}
          today={today}
          currentDate={currentDate}
          onChangeDate={handleChangeDate}
          channels={account.channels}
          selectedChannelIds={selectedIds}
          handleChannelChange={handleChannelChange}
        />
        <DragDropContextWrapper>
          <MonthGrid
            currentDate={currentDate}
            entries={activities}
            isLoading={isLoading}
          />
          <div ref={monthEndRef} />
        </DragDropContextWrapper>
      </MonthBody>
      {isPlannerMode && (
        <MonthSidebar className="active">
          <TaskPanel
            onClose={handleCloseSidebar}
            account={account}
            initData={location.state}
          />
        </MonthSidebar>
      )}
    </MonthLayout>
  );
}

const mapStateToProps = state => {
  //hide activities for for which we are showing an updated preview
  const filteredActivities = state.activity.activities
    .filter(activity => {
      return !(
        activity.type === "TASK" &&
        (activity.task.id === state.activity.hideTaskId ||
          (state.activity.hideTaskGroupId &&
            activity.task.taskGroupId === state.activity.hideTaskGroupId))
      );
    })
    .sort((a, b) => {
      return a.time < b.time ? -1 : 1;
    });
  const activities = [...filteredActivities, ...state.activity.previews];
  return {
    activities: activities,
    modalState: state.modals
  };
};

export default connect(mapStateToProps, {
  saveActivities: activityActions.saveActivities
})(Month);

import styled from "styled-components";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import React, { useState, useRef, useCallback, useMemo } from "react";
import { Flex, Box } from "rebass/styled-components";
import { ArrowBack, Plus } from "@styled-icons/boxicons-regular";
import { SwitchTransition, Transition } from "react-transition-group";

import {
  MenuList,
  MenuItem,
  MenuLink,
  MenuHeader,
  MenuChannelItem,
  MenuAccountItem
} from "./Menu";
import { TooltipInfo } from "./styles";
import { Headline5 } from "../common/styles";
import Popper from "components/common/Popper";
import FallbackImage from "../common/FallbackImage";
import { useAppState } from "contextApi/appContext";
import IconButton from "components/common/IconButton";
import emptyProfileIcon from "assets/images/profile-icon.png";

const Avatar = styled.figure`
  align-items: center;
  border-radius: 90px;
  background-color: white;
  box-shadow: ${props =>
    props.hasNotification
      ? `0 0 0 2px white, 0 0 0 4px ${props.theme.colors.primary}`
      : `0 0 0 0 white, 0 0 0 -2px ${props.theme.colors.primary}`};
  cursor: pointer;
  display: flex;
  height: 36px;
  justify-content: center;
  overflow: visible;
  position: relative;
  transition: 0.12s box-shadow;
  width: 36px;

  &::after {
    background-color: transparent;
    border-radius: 90px;
    box-shadow: inset 0 0 0 1px ${props => props.theme.colors.grey03};
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
  }

  &:hover {
    &::after {
      background-color: ${props => props.theme.colors.grey03};
    }
  }

  & img {
    border-radius: 90px;
    box-shadow: inset 0 0 0 1px ${props => props.theme.colors.grey03};
  }
`;

const UserMenuContainer = styled(Box)`
  background: white;
  border-radius: 16px;
  box-shadow:
    inset 0 0 0 1px ${props => props.theme.colors.grey02},
    0px 30px 60px -30px rgba(0, 0, 0, 0.15),
    0px 50px 100px -20px rgba(0, 0, 0, 0.2),
    0px 0px 1px rgba(0, 0, 0, 0.08);
  max-height: calc(100vh - 96px);
  overflow-x: hidden;
  overflow-y: auto;
  padding: 8px 0;
  transition: 0.25s height;
  width: 320px;
`;

const UserMenuView = styled(Box)`
  transition: 0.25s;
  opacity: ${({ state }) => (state === "entered" ? 1 : 0)};
  transform: ${({ state }) =>
    state === "entering"
      ? "translateX(24px)"
      : state === "exiting"
        ? "translateX(-24px)"
        : "translateX(0)"};
  display: ${({ state }) => (state === "exited" ? "none" : "block")};
`;

const UserMenuHeader = styled(Box)`
  display: grid;
  grid-template-columns: 36px 1fr;
  grid-column-gap: 12px;
  padding: 6px 16px;
`;

const UserMenuSection = styled(Box)`
  border-bottom: ${props =>
    !props.last ? `1px solid ${props.theme.colors.grey02}` : "none"};
  padding: ${props => (!props.first ? "8px" : "2px")} 0
    ${props => (!props.last ? "8px" : "2px")} 0;
`;

const AddChannelButton = styled(IconButton)`
  background: transparent;
  color: ${props => props.theme.colors.text02};
  height: 18px;
  margin-right: 16px;
  margin-bottom: -2px;
  padding: 0;
  width: 20px;

  &:hover {
    background: transparent;
    color: ${props => props.theme.colors.text01};
  }
`;

const UserMenu = React.memo(({ account }) => {
  const history = useHistory();
  const menuViewRef = useRef(null);
  const accountViewRef = useRef(null);
  const user = useSelector(state => state.auth.currentUser);
  const client = useAppState(state => state.clientSettings.client);
  const showWillowExtension = useAppState(state => state.showWillowExtension);
  const showReferralPage = useAppState(state => state.showReferralPage);
  const [menuHeight, setMenuHeight] = useState("auto");
  const [showOptions, setShowOptions] = useState(false);
  const [switchAccountView, setSwitchAccountView] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);

  const toggleShowOptions = useCallback(
    () => setShowOptions(showOptions => !showOptions),
    []
  );

  const hideOptions = useCallback(() => {
    setShowOptions(false);
  }, []);

  const updateMenuHeight = useCallback(
    node => {
      if (
        (switchAccountView && node.id === "accountView") ||
        (!switchAccountView && node.id === "menuView")
      ) {
        setMenuHeight(node.offsetHeight + 16);
      } else {
        setMenuHeight("auto");
      }
    },
    [switchAccountView]
  );

  const handleSettingsClick = useCallback(
    path => {
      history.push(`/accounts/${account.id}/settings/${path}`);
      setShowOptions(false);
    },
    [account.id, history]
  );

  const handleSwitchAccounts = useCallback(
    accountId => {
      history.push(`/accounts/${accountId}/dashboard`);
    },
    [history]
  );

  const handleLogout = useCallback(() => {
    history.push(`/logout`);
  }, [history]);

  const filteredAccounts = useMemo(
    () =>
      !!client && client === "telenet"
        ? user.accounts.filter(account => account.isTelenetCustomer)
        : user.accounts.filter(account => !account.isTelenetCustomer),
    [client, user.accounts]
  );

  return (
    <div ref={setReferenceElement}>
      <Avatar
        data-tip="Account"
        id={"userMenuAvatar"}
        hasNotification={showOptions}
        onClick={toggleShowOptions}
      >
        <FallbackImage
          alt={account.companyName}
          src={account.logo}
          fallbackSrc={emptyProfileIcon}
          width={36}
          height={36}
        />
      </Avatar>
      <TooltipInfo effect={"solid"} place={"bottom"} />
      <Popper
        offset={[-270, 10]}
        referenceElement={referenceElement}
        visible={showOptions}
        onClose={hideOptions}
        exceptions={[referenceElement]}
      >
        <UserMenuContainer height={menuHeight}>
          <SwitchTransition mode="out-in">
            <Transition
              key={switchAccountView ? "accounts" : "menu"}
              addEndListener={(node, done) => {
                updateMenuHeight(node);
                node.addEventListener("transitionend", done, false);
              }}
              unmountOnExit
              mountOnEnter
              timeout={125}
            >
              {state =>
                !switchAccountView ? (
                  <UserMenuView state={state} ref={menuViewRef} id="menuView">
                    <UserMenuSection first>
                      <Box px={8}>
                        <MenuAccountItem
                          thumbnail={account.logo}
                          thumbnailSize={44}
                          thumbnailRound={true}
                          title={`${user.firstName} ${user.lastName}`}
                          description={account.companyName}
                          onClick={() => handleSettingsClick("account")}
                        />
                      </Box>
                    </UserMenuSection>
                    <UserMenuSection>
                      <MenuList>
                        <MenuItem
                          label="Channels"
                          icon="send"
                          onClick={() => handleSettingsClick("channels")}
                        />
                        {(!client || client !== "telenet") && (
                          <MenuItem
                            label="Team"
                            icon="team"
                            onClick={() => handleSettingsClick("team")}
                          />
                        )}
                        <MenuItem
                          label="Hashtags"
                          icon="hashtag"
                          onClick={() => handleSettingsClick("hashtags")}
                        />
                        <MenuItem
                          label="Notifications"
                          icon="notification"
                          onClick={() => handleSettingsClick("notifications")}
                        />
                        <MenuItem
                          label="Subscription"
                          icon="billing"
                          onClick={() => handleSettingsClick("billing")}
                        />
                        <MenuItem
                          label="Security"
                          icon="security"
                          onClick={() => handleSettingsClick("security")}
                        />
                        {showReferralPage && (
                          <MenuItem
                            label="Referral"
                            icon="present"
                            onClick={() => handleSettingsClick("referral")}
                          />
                        )}
                        {showWillowExtension && (
                          <MenuItem
                            label="Willow+ Extension"
                            icon="sparkle"
                            onClick={() => handleSettingsClick("willow+")}
                          />
                        )}
                      </MenuList>
                    </UserMenuSection>
                    <UserMenuSection>
                      <MenuList maxHeight={240}>
                        <Flex
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <MenuHeader>
                            <span>Channels</span>
                          </MenuHeader>
                          {account.channels.length > 0 && (
                            <AddChannelButton
                              onClick={() => handleSettingsClick("channels")}
                              icon={Plus}
                            />
                          )}
                        </Flex>
                        {account.channels.length > 0 ? (
                          account.channels.map(item => (
                            <MenuChannelItem
                              account={account}
                              toReconnect={item.needsReconnection}
                              key={item.id}
                              channel={item}
                              label={item.username}
                            />
                          ))
                        ) : (
                          <MenuLink
                            label="Connect channel"
                            onClick={() => handleSettingsClick("channels")}
                          />
                        )}
                      </MenuList>
                    </UserMenuSection>
                    <UserMenuSection last>
                      <MenuList>
                        <MenuItem
                          label="Log out"
                          icon="signout"
                          onClick={handleLogout}
                        />
                        {filteredAccounts.length > 1 && (
                          <MenuItem
                            label="Switch account"
                            icon="swap"
                            hasArrow
                            onClick={() => setSwitchAccountView(true)}
                          />
                        )}
                      </MenuList>
                    </UserMenuSection>
                  </UserMenuView>
                ) : (
                  <UserMenuView
                    state={state}
                    ref={accountViewRef}
                    id="accountView"
                  >
                    <UserMenuSection first>
                      <UserMenuHeader>
                        <IconButton
                          onClick={() => setSwitchAccountView(false)}
                          icon={ArrowBack}
                          size={36}
                          variant="secondary"
                        />
                        <Headline5>Accounts</Headline5>
                      </UserMenuHeader>
                    </UserMenuSection>
                    <UserMenuSection last>
                      <MenuList>
                        {filteredAccounts.map(acc => (
                          <MenuItem
                            key={acc.id}
                            image={acc.logo}
                            label={acc.companyName}
                            onClick={() => handleSwitchAccounts(acc.id)}
                          />
                        ))}
                      </MenuList>
                    </UserMenuSection>
                  </UserMenuView>
                )
              }
            </Transition>
          </SwitchTransition>
        </UserMenuContainer>
      </Popper>
    </div>
  );
});

export default UserMenu;

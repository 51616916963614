import React, { useState } from "react";
import {
  StyledSideNavigation,
  SideNavigationItem,
  SideNavigationIcon,
  SideNavigationLabel,
  SideNavigationChevron,
  SideNavigationSection,
  StyledNavigationDropdown
} from "./styles";
import { ChevronDown } from "styled-icons/boxicons-regular";
import useWindowSize from "../../utils/useWindowSize";
import { connect } from "react-redux";
import { Flex } from "rebass";
import { ToolTip } from "components/schedule/common/styles";

function SideNavigationDropdown({ item, isExpanded, onClick }) {
  const { label, icon } = item;
  return (
    <StyledNavigationDropdown onClick={onClick}>
      {icon && <SideNavigationIcon className={icon}></SideNavigationIcon>}
      <span>{label}</span>
      <SideNavigationChevron
        component={React.createElement(ChevronDown)}
        size={20}
        isExpanded={isExpanded}
      />
    </StyledNavigationDropdown>
  );
}

function SideNavigationLink({
  item,
  match,
  isCurrent,
  onClick,
  collapsed,
  px,
  py,
  strong
}) {
  const { label, icon, activeIcon } = item;

  return (
    <>
      <SideNavigationItem
        to={`${match.url}${item.to}`}
        onClick={onClick}
        active={String(isCurrent)}
        px={px}
        py={py}
        data-tip
        data-for={label}
        strong={String(strong)}
      >
        <Flex justifyContent="flex-start" alignItems="center">
          {icon && (
            <SideNavigationIcon
              className={`${isCurrent && activeIcon ? activeIcon : icon} icon`}
            ></SideNavigationIcon>
          )}
          {collapsed ? "" : <SideNavigationLabel>{label}</SideNavigationLabel>}
        </Flex>
      </SideNavigationItem>
      {collapsed && (
        <ToolTip id={label} place="right" className="tooltip" variant="info">
          {label}
        </ToolTip>
      )}
    </>
  );
}

function SideNavigation({
  match,
  links,
  account,
  collapsed = false,
  px,
  py,
  strong = false
}) {
  const location = window.location.pathname;
  const [isExpanded, setExpanded] = useState(false);
  const windowSize = useWindowSize();

  const currentNavItem = links.find(
    item => `${match.url}${item.to}` === window.location.pathname
  );

  const isCurrent = href => href.split("?")[0] === location;

  const handleDropdownToggle = value => {
    if (windowSize.size === "xsmall") setExpanded(value);
    return;
  };

  const featureAwareLinks = links.filter(
    ({ requiredFeature }) =>
      !requiredFeature || (account.features ?? []).includes(requiredFeature)
  );

  return (
    <StyledSideNavigation>
      {currentNavItem && (
        <SideNavigationDropdown
          item={currentNavItem}
          isExpanded={isExpanded}
          onClick={() => handleDropdownToggle(!isExpanded)}
        />
      )}
      {(windowSize.size !== "xsmall" || isExpanded) &&
        featureAwareLinks.map((item, i) =>
          item.type === "link" ? (
            <SideNavigationLink
              key={`${item.type}-${item.label}`}
              item={item}
              match={match}
              onClick={() => handleDropdownToggle(false)}
              isCurrent={isCurrent(`${match.url}${item.to}`)}
              collapsed={collapsed}
              px={px}
              py={py}
              strong={strong}
            />
          ) : (
            <SideNavigationSection key={`${item.type}-${item.label}`}>
              {item.label}
            </SideNavigationSection>
          )
        )}
      {/* No Idea on what it does, so just commenting it out for now */}
      {/* <RegionFilterSelect /> */}
    </StyledSideNavigation>
  );
}

const mapStateToProps = state => {
  return {
    account: state.account.data
  };
};

export default connect(mapStateToProps, {})(SideNavigation);

import {
  Context,
  useContext,
  createContext,
  useContextSelector
} from "use-context-selector";
import React, { useEffect, useReducer, useCallback } from "react";

import {
  IActivities,
  initialValues,
  IDashboardState,
  dashboardReducer,
  IDashboardDispatch
} from "./reducer";
import {
  updateStatsAction,
  updateActivitiesAction,
  updateSocialHealthAction,
  updateStatsFetchingStateAction,
  updateActivitiesFetchingStateAction,
  updateSocialHealthFetchingStateAction
} from "./actionCreators";
import {
  fetchDashboardStats,
  fetchDashboardSocialHealth
} from "services/dashboard";
// @ts-ignore
import { useToaster } from "@hellocontento/maillard";
import { useComposerState } from "contextApi/composerContext";
import { fetchDashboardActivities } from "services/activities";

const DashboardStateContext = createContext<IDashboardState | undefined>(
  undefined
);
DashboardStateContext.displayName = "DashboardStateContext";

const DashboardDispatchContext = createContext<IDashboardDispatch | undefined>(
  undefined
);
DashboardDispatchContext.displayName = "DashboardDispatchContext";

export interface IDashboardActions {}

const DashboardActionsContext = createContext<IDashboardActions | undefined>(
  undefined
);
DashboardActionsContext.displayName = "DashboardActionsContext";

const filterActivitiesByPostType = (activities: any[]): IActivities => {
  const filteredActivities: IActivities = {
    published: [],
    scheduled: [],
    todos: []
  };

  activities.forEach((activity: any) => {
    if (activity.type === "TASK") {
      filteredActivities.todos.push(activity);
    } else if (activity.type === "DRAFT_POST") {
      filteredActivities.todos.push(activity);
      filteredActivities.scheduled.push(activity);
    } else if (!activity.post.isStuck) {
      if (activity.post.status === "SCHEDULED") {
        filteredActivities.scheduled.push(activity);
      } else if (activity.post.status === "SENT") {
        filteredActivities.published.push(activity);
      }
    }
  });

  return filteredActivities;
};

export const DashboardProvider: React.FC<any> = React.memo(({ children }) => {
  const addToast = useToaster();
  const onPostedEvent = useComposerState(state => state.events.onPostedEvent);
  const onReloadEvent = useComposerState(state => state.events.onReloadEvent);
  const onDeletedEvent = useComposerState(state => state.events.onDeletedEvent);
  const [state, dispatch] = useReducer(dashboardReducer, {
    ...initialValues
  });

  const fetchActivities = useCallback(async () => {
    try {
      dispatch(updateActivitiesFetchingStateAction(true));
      const activities = await fetchDashboardActivities();
      const filteredActivities = filterActivitiesByPostType(activities);

      dispatch(updateActivitiesAction(filteredActivities));
    } catch (error) {
      addToast((error as any).message, "error");
    } finally {
      dispatch(updateActivitiesFetchingStateAction(false));
    }
  }, [addToast]);

  const fetchSocialHealth = useCallback(async () => {
    try {
      dispatch(updateSocialHealthFetchingStateAction(true));
      const socialHealth = await fetchDashboardSocialHealth(
        state.filterRange.periodInDays
      );

      dispatch(updateSocialHealthAction(socialHealth));
    } catch (error) {
      addToast((error as any).message, "error");
    } finally {
      dispatch(updateSocialHealthFetchingStateAction(false));
    }
  }, [addToast, state.filterRange.periodInDays]);

  const fetchStats = useCallback(async () => {
    try {
      dispatch(updateStatsFetchingStateAction(true));
      const stats = await fetchDashboardStats(state.filterRange.periodInDays);

      dispatch(updateStatsAction(stats));
    } catch (error) {
      addToast((error as any).message, "error");
    } finally {
      dispatch(updateStatsFetchingStateAction(false));
    }
  }, [addToast, state.filterRange.periodInDays]);

  useEffect(() => {
    fetchActivities();
    fetchSocialHealth();
    fetchStats();
  }, [fetchActivities, fetchSocialHealth, fetchStats]);

  useEffect(
    () => onPostedEvent.listen(fetchActivities),
    [onPostedEvent, fetchActivities]
  );

  useEffect(
    () => onReloadEvent.listen(fetchActivities),
    [onReloadEvent, fetchActivities]
  );

  useEffect(
    () => onDeletedEvent.listen(fetchActivities),
    [onDeletedEvent, fetchActivities]
  );

  return (
    <DashboardStateContext.Provider value={state}>
      <DashboardDispatchContext.Provider value={dispatch}>
        <DashboardActionsContext.Provider value={{}}>
          {children}
        </DashboardActionsContext.Provider>
      </DashboardDispatchContext.Provider>
    </DashboardStateContext.Provider>
  );
});

export const useDashboardState = <T,>(
  selector: (state: IDashboardState) => T
): T => {
  try {
    return useContextSelector(
      DashboardStateContext as Context<IDashboardState>,
      selector
    );
  } catch (_) {
    throw new Error(
      "useDashboardState must be used within a DashboardProvider"
    );
  }
};

export const useDashboardDispatch = (): IDashboardDispatch => {
  try {
    return useContext<IDashboardDispatch>(
      DashboardDispatchContext as Context<IDashboardDispatch>
    );
  } catch (_) {
    throw new Error(
      "useDashboardDispatch must be used within a DashboardProvider"
    );
  }
};

export const useDashboardActions = <T,>(
  selector: (state: IDashboardActions) => T
): T => {
  try {
    return useContextSelector(
      DashboardActionsContext as Context<IDashboardActions>,
      selector
    );
  } catch (_) {
    throw new Error(
      "useDashboardActions must be used within a DashboardProvider"
    );
  }
};

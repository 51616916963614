import React, { useCallback, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import {
  Page,
  PageName,
  PageHeader,
  SearchInput,
  ConnectionList,
  ConnectionListWrapper
} from "./styles";
import { Headline4 } from "components/common/styles";
import Checkbox from "components/common/form/Checkbox";
import { ChannelAvatar, Avatar } from "components/common/styles";
import DefaultAvatar from "assets/images/dashboard/avatar-empty-state.png";

interface ILinkedInBusiness {
  id: string;
  avatar: string;
  vanityName: string;
}

interface ILinkedInBusinessRow {
  page: ILinkedInBusiness;
  selected: boolean;
  onToggle: VoidFunction;
}

const LinkedInBusinessRow: React.FC<ILinkedInBusinessRow> = ({
  page,
  onToggle,
  selected
}) => {
  return (
    <Page checked={selected}>
      <PageHeader>
        <ChannelAvatar
          key={page.id}
          mr={0}
          // @ts-ignore
          enabled={true}
          clickable={false}
        >
          <Avatar
            src={page.avatar ? page.avatar : DefaultAvatar}
            // @ts-ignore
            isRounded={true}
            width={40}
            height={40}
            noShadow
            onError={e => {
              e.currentTarget.onerror = null;
              e.currentTarget.src = DefaultAvatar;
            }}
          />
        </ChannelAvatar>
        <PageName>
          <label htmlFor={`checkbox-${page.id}`}>{page.vanityName}</label>
        </PageName>
      </PageHeader>
      <Checkbox
        name={`checkbox-${page.id}`}
        checked={selected}
        onChange={onToggle}
      />
    </Page>
  );
};

interface ILinkedInBusinessList {
  pages: ILinkedInBusiness[];
  selectedPageIds: string[];
  setSelectedPageIds: (ids: string[]) => void;
}

const LinkedInBusinessList: React.FC<
  RouteComponentProps & ILinkedInBusinessList
> = ({ pages, selectedPageIds, setSelectedPageIds }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = useCallback(e => {
    setSearchTerm(e.currentTarget.value);
  }, []);

  const handleToggle = useCallback(
    pageId => {
      let newSelectedPages: string[] = [];
      if (selectedPageIds.includes(pageId)) {
        newSelectedPages = selectedPageIds.filter(
          selectedPageId => selectedPageId !== pageId
        );
      } else {
        newSelectedPages = selectedPageIds.concat([pageId]);
      }
      setSelectedPageIds(newSelectedPages);
    },
    [selectedPageIds]
  );

  return (
    <ConnectionListWrapper>
      {pages.length < 1 ? (
        <Headline4>LinkedIn pages not available for the profile</Headline4>
      ) : (
        <>
          <SearchInput
            type="text"
            value={searchTerm}
            placeholder="Search accounts"
            onChange={handleSearchChange}
          />
          <ConnectionList>
            {pages
              .filter(page =>
                page.vanityName.toLowerCase().includes(searchTerm.toLowerCase())
              )
              .map(page => (
                <LinkedInBusinessRow
                  key={page.id}
                  page={page}
                  selected={selectedPageIds.includes(page.id)}
                  onToggle={() => handleToggle(page.id)}
                />
              ))}
          </ConnectionList>
        </>
      )}
    </ConnectionListWrapper>
  );
};

export default withRouter(LinkedInBusinessList);

import React from "react";
import {
  PostAttachmentImage,
  ImageGrid,
  ImageArea,
  AttachmentImage
} from "./styles";

const MAX_GRID = 4;

function PostAttachmentImageComponent({ attachment, height = 200, maxHeight }) {
  const urls = Array.isArray(attachment.url)
    ? attachment.url
    : [attachment.url];

  return (
    <PostAttachmentImage>
      <ImageGrid
        size="sm"
        gridSize={Math.min(urls.length, MAX_GRID)}
        rowHeight={typeof height === "string" && height}
      >
        {urls.map(
          (item, i) =>
            i < MAX_GRID && (
              <ImageArea
                key={item}
                gridArea={`grid-item-${i + 1}`}
                maxHeight={
                  !maxHeight || typeof height === "string"
                    ? undefined
                    : i === 0
                      ? urls.length > 3
                        ? height - 1
                        : maxHeight
                      : urls.length > 3
                        ? height - 1
                        : urls.length === 2
                          ? maxHeight
                          : maxHeight / 2 - (urls.length === 3 ? 1 : 0)
                }
              >
                <AttachmentImage src={item} alt={"Attached image"} />
              </ImageArea>
            )
        )}
        {urls.length > MAX_GRID && (
          <ImageArea overlay gridArea={"grid-item-4"}>
            <span>+{urls.length - (MAX_GRID - 1)}</span>
          </ImageArea>
        )}
      </ImageGrid>
    </PostAttachmentImage>
  );
}

export default PostAttachmentImageComponent;

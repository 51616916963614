import React from "react";

import {
  Th,
  Td,
  Table,
  TableContainer,
  PostText,
  PostTextContainer,
  NA,
  PostLinkIcon
} from "../styles";
import { ChannelAvatar, Avatar, Service } from "components/common/styles";
import defaultAvatar from "assets/images/dashboard/avatar-empty-state.png";
import { IPostWithMetrics } from "@hellocontento/contento-common";
import { Flex, Link } from "rebass/styled-components";
import Tooltip from "components/common/Tooltip";
import { getExternalPostUrl } from "utils/channels";

interface SortConfig {
  key: string;
  direction: "ascending" | "descending";
}

interface LinksTableContentProps {
  data: IPostWithMetrics[];
  sortConfig: SortConfig | null;
  sortData: (key: string) => void;
}

const LinksTableContent: React.FC<LinksTableContentProps> = ({
  data,
  sortConfig,
  sortData
}) => {
  const formatDate = (dateString: string) => {
    if (!dateString) return <NA>N/A</NA>;
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-GB", {
      weekday: "short",
      day: "2-digit",
      month: "short",
      year: "numeric"
    }).format(date);
  };

  return (
    <TableContainer>
      <Table>
        <thead>
          <tr>
            <Th
              isSorted={false}
              direction="ascending"
              showArrow={false}
              index={0}
            >
              Account
            </Th>
            <Th
              isSorted={false}
              direction="ascending"
              showArrow={false}
              index={1}
            >
              Post
            </Th>
            <Th
              isSorted={sortConfig?.key === "postedAt"}
              direction={
                sortConfig?.key === "postedAt"
                  ? sortConfig.direction
                  : "ascending"
              }
              onClick={() => sortData("postedAt")}
              showArrow={true}
              index={2}
            >
              Created At
            </Th>
            <Th
              isSorted={sortConfig?.key === "impressions"}
              direction={
                sortConfig?.key === "impressions"
                  ? sortConfig.direction
                  : "ascending"
              }
              onClick={() => sortData("impressions")}
              showArrow={true}
              index={3}
            >
              Post Views
            </Th>
            <Th
              isSorted={sortConfig?.key === "clicks"}
              direction={
                sortConfig?.key === "clicks"
                  ? sortConfig.direction
                  : "ascending"
              }
              onClick={() => sortData("clicks")}
              showArrow={true}
              index={4}
            >
              Link Clicks
            </Th>
            <Th
              isSorted={sortConfig?.key === "engagementRate"}
              direction={
                sortConfig?.key === "engagementRate"
                  ? sortConfig.direction
                  : "ascending"
              }
              onClick={() => sortData("engagementRate")}
              showArrow={true}
              index={5}
            >
              Engagement Rate
            </Th>
          </tr>
        </thead>
        <tbody>
          {data.map((post, index) => (
            <tr key={index}>
              <Td isSorted={false} index={0}>
                <ChannelAvatar
                  key={post.channel.id}
                  mr={0}
                  style={{ opacity: 1, cursor: "default" }}
                >
                  <Avatar
                    src={post.channel.avatar ?? defaultAvatar}
                    width={28}
                    height={28}
                    style={{
                      boxShadow: "none",
                      filter: "none",
                      opacity: 1,
                      border: "1px solid transparent"
                    }}
                    onError={event => {
                      (event.target as HTMLImageElement).src = defaultAvatar;
                      (event.target as HTMLImageElement).onerror = null;
                    }}
                  />
                  <Service
                    size={16}
                    key={post.channel.id}
                    title={post.channel.username}
                    type={post.channel.service}
                  />
                </ChannelAvatar>
              </Td>
              <Td isSorted={false} index={1} textAlign="left">
                <PostTextContainer>
                  <Tooltip title="Open Original Post" placement="bottom">
                    <PostLinkIcon
                      onClick={() =>
                        window.open(getExternalPostUrl(post) ?? "", "_blank")
                      }
                    />
                  </Tooltip>
                  <Flex sx={{ flexDirection: "column", gap: "4px" }}>
                    <PostText maxLines={1} color="#0063FB">
                      {post.caption || <NA>N/A</NA>}
                    </PostText>
                    <Link
                      href={post.attachment?.url}
                      target="_blank"
                      sx={{
                        textDecoration: "underline",
                        color: "#3C3F4254"
                      }}
                    >
                      {post.attachment?.url}
                    </Link>
                  </Flex>
                </PostTextContainer>
              </Td>
              <Td isSorted={sortConfig?.key === "postedAt"} index={2}>
                {formatDate(post.postedAt)}
              </Td>
              <Td isSorted={sortConfig?.key === "impressions"} index={3}>
                {post.stats.raw.impressions ?? <NA>N/A</NA>}
              </Td>
              <Td isSorted={sortConfig?.key === "clicks"} index={4}>
                {post.attachment.clicks ?? <NA>N/A</NA>}
              </Td>
              <Td isSorted={sortConfig?.key === "engagementRate"} index={5}>
                {post.stats.calculated.engagementRate != null ? (
                  `${post.stats.calculated.engagementRate}%`
                ) : (
                  <NA>N/A</NA>
                )}
              </Td>
            </tr>
          ))}
        </tbody>
      </Table>
    </TableContainer>
  );
};

export default LinksTableContent;

import { useSelector } from "react-redux";
import React, { useState, useEffect, useMemo } from "react";

import {
  useAnalyticsState,
  useAnalyticsActions
} from "contextApi/analyticsContext";
import LinksTableHeader from "./LinksTableHeader";
import { NoDataForTheseCriteria } from "../styles";
import LinksTableContent from "./LinksTableContent";
import Loader from "../widgets/Loader";

interface Channel {
  id: string;
  avatar: string;
  username: string;
  service: string;
}

interface AccountState {
  data: {
    id: string;
    channels: Channel[];
  };
}

interface RootState {
  account: AccountState;
}

const LinksTable: React.FC = () => {
  const account = useSelector((state: RootState) => state.account.data);
  const data = useAnalyticsState(state => state.links.data);
  const setData = useAnalyticsActions(state => state.setLinksData);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: "ascending" | "descending";
  } | null>(null);
  const isLoading = useAnalyticsState(state => state.links.isLoading);
  const selectedChannels = useAnalyticsState(state => state.links.channelIds);
  const setSelectedChannels = useAnalyticsActions(
    state => state.setLinksChannelIds
  );
  const period = useAnalyticsState(state => state.links.period);
  const setPeriod = useAnalyticsActions(state => state.setLinksPeriod);
  const refreshLinkPosts = useAnalyticsActions(state => state.refreshLinkPosts);

  const selectedCategories = useAnalyticsState(
    state => state.links.categories
  );

   const filteredData = useMemo(
     () => data.filter(p => selectedCategories.includes(p.contentTypeId)),
     [data, selectedCategories]
   );

  useEffect(() => {
    refreshLinkPosts();
  }, [refreshLinkPosts]);

  const sortData = (key: string) => {
    let direction: "ascending" | "descending" = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }

    const sortedData = [...data].sort((a, b) => {
      const aValue =
        key === "postedAt"
          ? new Date(a[key])
          : a.attachment[key] ?? a.stats.raw[key] ?? a.stats.calculated[key];
      const bValue =
        key === "postedAt"
          ? new Date(b[key])
          : b.attachment[key] ?? b.stats.raw[key] ?? b.stats.calculated[key];

      if (aValue < bValue) {
        return direction === "ascending" ? -1 : 1;
      }
      if (aValue > bValue) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setData(sortedData);
    setSortConfig({ key, direction });
  };

  return (
    <div>
      <LinksTableHeader
        loading={isLoading}
        period={period}
        setPeriod={setPeriod}
        selectedChannels={selectedChannels}
        setSelectedChannels={setSelectedChannels}
        account={account}
      />
      {isLoading && <Loader />}
      {/* {error && <div>{error}</div>} */}
      {!isLoading && /* !error && */ filteredData.length === 0 && (
        <NoDataForTheseCriteria>
          No posts available. Please adjust your filters or search criteria.
        </NoDataForTheseCriteria>
      )}
      {!isLoading && /* !error && */ filteredData.length > 0 && (
        <LinksTableContent
          data={filteredData}
          sortConfig={sortConfig}
          sortData={sortData}
        />
      )}
    </div>
  );
};

export default LinksTable;

import _ from "lodash";
import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Flex } from "rebass/styled-components";

import WidgetContainer, { WidgetHeader } from "./WidgetContainer";
import { Label, Avatar, ChannelAvatar } from "components/common/styles";
import defaultAvatar from "assets/images/dashboard/avatar-empty-state.png";

const DataTableContainer = styled.div``;

const DataTableHeader = styled.div``;

const DataTableRow = styled.div`
  display: grid;
  grid-template-columns: ${props => props.cols};

  ${props =>
    !props.isHeader &&
    css`
      border-top: 1px solid ${props => props.theme.colors.grey02};
    `}
`;

const DataTableCell = styled.div`
  align-items: center;
  color: ${props =>
    props.isSelectedCol
      ? props.theme.colors.text01
      : props.theme.colors.text02};
  display: flex;
  font-size: ${props => (props.isHeading ? 12 : 14)}px;
  font-weight: ${props =>
    props.isSelectedCol ? 700 : props.isHeading ? 600 : 400};
  padding: 12px 24px;

  ${props =>
    props.isHeading &&
    css`
      &:hover {
        color: ${props => props.theme.colors.text01};
        cursor: pointer;
      }
    `}
`;

const dummyTableHeaders = ["#", "Account Name", "Posts", "Engagement"];

function DataTable({
  header = dummyTableHeaders,
  data,
  cols = "56px 2fr 1fr 1fr"
}) {
  const [selectedColumn, setSelectedColumn] = useState(0);

  const handleHeadingSelect = i => {
    setSelectedColumn(i);
  };

  return (
    <DataTableContainer>
      {header && (
        <DataTableHeader>
          <DataTableRow cols={cols} isHeader>
            {header.map((heading, i) => (
              <DataTableCell
                key={heading}
                onClick={() => handleHeadingSelect(i)}
                isSelectedCol={i === selectedColumn}
                isHeading
              >
                {heading}
              </DataTableCell>
            ))}
          </DataTableRow>
        </DataTableHeader>
      )}
      {data &&
        data.map((row, j) => (
          <DataTableRow cols={cols} key={`row-${j}`} ﬂ>
            {Object.entries(row).map(([key, val], i) => (
              <DataTableCell
                key={`${key}-${i}-${+new Date()}`}
                isSelectedCol={i === selectedColumn}
              >
                {val}
              </DataTableCell>
            ))}
          </DataTableRow>
        ))}
    </DataTableContainer>
  );
}

export default function Leaderboard({ data, period, max = 10 }) {
  const tableData = _.sortBy(data, emp => emp.stats?.posts?.total)
    .reverse()
    .slice(0, max)
    .map((emp, idx) => ({
      pos: idx + 1,
      accountName: (
        <Flex alignItems="center">
          <ChannelAvatar enabled={true} ml={8}>
            <Avatar
              src={emp.avatar ?? defaultAvatar}
              width={40}
              height={40}
              noShadow={true}
              isRounded={true}
              onError={event => {
                event.target.src = defaultAvatar;
                event.onerror = null;
              }}
            />
          </ChannelAvatar>
          <Label ml={16}>{emp.username}</Label>
        </Flex>
      ),
      posts: emp.stats.posts.total,
      engagement: emp.stats.engagements.total
    }));

  return data.length > 0 ? (
    <WidgetContainer width={"100%"}>
      <WidgetHeader
        title="Leaderboard"
        description={`last ${period} days · LinkedIn Profiles`}
      />
      <DataTable data={tableData} />
    </WidgetContainer>
  ) : null;
}

export const OPEN_COMPOSER = "@@composerContext/OPEN_COMPOSER";
export const CLOSE_COMPOSER = "@@composerContext/CLOSE_COMPOSER";
export const TOGGLE_PRO_MODE = "@@composerContext/TOGGLE_PRO_MODE";
export const SET_IN_DRAFT_MODE = "@@composerContext/SET_IN_DRAFT_MODE";
export const SET_POST_DATA = "@@composerContext/SET_POST_DATA";
export const RESET_POST_DATA = "@@composerContext/RESET_POST_DATA";
export const ENABLE_SPLIT_MODE = "@@composerContext/ENABLE_SPLIT_MODE";
export const DISABLE_SPLIT_MODE = "@@composerContext/DISABLE_SPLIT_MODE";
export const SET_TAGS = "@@composerContext/SET_TAGS";
export const SET_EDITOR_STATE_OBJ = "@@composerContext/SET_EDITOR_STATE_OBJ";
export const SET_VISIBLE_CAPTION = "@@composerContext/SET_VISIBLE_CAPTION";
export const SET_VALIDATION_ERRORS = "@@composerContext/SET_VALIDATION_ERRORS";
export const SET_CHOSEN_SUGGESTED_CAPTION =
  "@@composerContext/SET_CHOSEN_SUGGESTED_CAPTION";
export const SET_POST_CONCEPTS = "@@composerContext/SET_POST_CONCEPTS";
export const SET_SUGGESTED_DESIGNS = "@@composerContext/SET_SUGGESTED_DESIGNS";
export const SET_POST_IDEA_CAPTIONS =
  "@@composerContext/SET_POST_IDEA_CAPTIONS";
export const SET_ATTACHMENT_TYPE = "@@composerContext/SET_ATTACHMENT_TYPE";
export const SET_IS_UPLOADING_ATTACHMENTS =
  "@@composerContext/SET_IS_UPLOADING_ATTACHMENTS";
export const ADD_ATTACHMENTS = "@@composerContext/ADD_ATTACHMENTS";
export const REMOVE_ATTACHMENT = "@@composerContext/REMOVE_ATTACHMENT";
export const REMOVE_ARTICLE_ATTACHMENT =
  "@@composerContext/REMOVE_ARTICLE_ATTACHMENT";
export const REORDER_ATTACHMENT = "@@composerContext/REORDER_ATTACHMENT";
export const SET_ATTACHMENT_VALIDATION_ERROR =
  "@@composerContext/SET_ATTACHMENT_VALIDATION_ERROR";
export const SET_ATTACHMENTS = "@@composerContext/SET_ATTACHMENTS";
export const SET_ATTACHMENT_OPTIONS =
  "@@composerContext/SET_ATTACHMENT_OPTIONS";
export const SET_IS_SUBMITTING = "@@composerContext/SET_IS_SUBMITTING";
export const SET_IS_DELETING = "@@composerContext/SET_IS_DELETING";
export const SET_WAS_SCHEDULE_CHANGED =
  "@@composerContext/SET_WAS_SCHEDULE_CHANGED";
export const SET_PICKED_DATE = "@@composerContext/SET_PICKED_DATE";
export const SET_MOOD_FILTER = "@@composerContext/SET_MOOD_FILTER";
export const SET_IS_LOADING_SUGGESTED_IMAGES =
  "@@composerContext/SET_IS_LOADING_SUGGESTED_IMAGES";
export const SET_NEW_IMAGES_INDICATION =
  "@@composerContext/SET_NEW_IMAGES_INDICATION";
export const SET_IMAGES_SEARCH_TERM =
  "@@composerContext/SET_IMAGES_SEARCH_TERM";
export const SET_SUGGESTED_IMAGES = "@@composerContext/SET_SUGGESTED_IMAGES";
export const SET_IMAGES_QUERY = "@@composerContext/SET_IMAGES_QUERY";
export const SET_ACTIVE_TOOLKIT = "@@composerContext/SET_ACTIVE_TOOLKIT";
export const SET_CONFIRMATION_MODAL_PROPS =
  "@@composerContext/SET_CONFIRMATION_MODAL_PROPS";
export const SET_IS_COMPOSER_CLOSE_CONFIRMATION_OPEN =
  "@@composerContext/SET_IS_COMPOSER_CLOSE_CONFIRMATION_OPEN";

import styled from "styled-components";
import React, { useCallback, useMemo, useState } from "react";

import Popper from "components/common/Popper";
import { StyledButton } from "components/common/Button";
import { ChevronDown } from "styled-icons/boxicons-regular";
import SimpleBar from "simplebar-react";
import { MenuContainer } from "components/navigation/Menu";
import { Box, Flex, Text } from "rebass/styled-components";
import Checkbox from "components/common/form/Checkbox";
import { generateRandomColor } from "utils/color";

const PostCategoryButton = styled(StyledButton)`
  background: transparent;
  color: ${props => props.theme.colors.text02};
  gap: 4px;
  display: flex;
  font-family: ${props => props.theme.fonts.body};
  height: 36px;
  padding: 0 10px;
  width: fit-content;
`;

export interface IPostCategoryOption {
  label: string;
  value: string;
  color: string
}

function PostCategoryItem({
  option,
  isSelected,
  onChange
}: {
  option: IPostCategoryOption;
  isSelected: boolean;
  onChange: (newVal: IPostCategoryOption) => void;
}) {

  return (
    <Flex
      sx={{ gap: "10px", padding: "6px 0px", alignItems: "center" }}
      onClick={() => onChange(option)}
    >
      <Checkbox
        type={"checkbox"}
        onChange={() => {}}
        checked={isSelected}
      />
      <Text
        fontSize={"14px"}
        lineHeight={"17.5px"}
        color={option.color}
        sx={{ textTransform: "capitalize" }}
      >
        {option.label}
      </Text>
    </Flex>
  );
}

interface IPostCategorySelector {
  selectedCategories?: string[];
  categories?: string[];
  onChange: (value: string[]) => void;
}

const PostCategorySelector: React.FC<IPostCategorySelector> = ({
  selectedCategories = [],
  categories = [],
  onChange
}) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const toggleToolTipVisibility = () => setTooltipVisible(!tooltipVisible);

  const [referenceElement, setReferenceElement] = useState(null);

  const categoryOptions: IPostCategoryOption[] = useMemo(
    () =>
      {
        return categories.map(category => ({
          label: category,
          value: category,
          color: generateRandomColor()
        }));
      },
    [categories]
  );

  const handleChange = (categoryOption: IPostCategoryOption) => {
    const alreadySelected = selectedCategories.find(
      category => category === categoryOption.value
    );
    let newSelection = Array.from(selectedCategories);
    if (alreadySelected) {
      newSelection = selectedCategories.filter(
        category => category !== categoryOption.value
      );
    } else {
      newSelection.push(categoryOption.value);
    }
    onChange(newSelection);
  };

  const getTitle = useCallback(() => {
    if (selectedCategories.length === 1) {
      return selectedCategories[0];
    }
    
    if (selectedCategories.length === categories.length) {
      return "All Categories";
    }

    return `${selectedCategories.length} Categories`;
  }, [selectedCategories, categories.length]);

  
  return (
    <PostCategoryButton ref={setReferenceElement} size="sm" variant="secondary">
      <Box onClick={toggleToolTipVisibility}>
        <Text fontWeight={400} sx={{textTransform: "capitalize"}}>
          {getTitle()}
        </Text>
      </Box>
      <ChevronDown size={16} />
      {/* @ts-ignore */}
      <Popper
        placement={"bottom-start"}
        referenceElement={referenceElement}
        visible={tooltipVisible}
        onClose={() => setTooltipVisible(false)}
        exceptions={[referenceElement]}
      >
        <MenuContainer minWidth={240}>
          <SimpleBar
            style={{
              maxHeight: 420,
              width: 288,
              overflowX: "hidden",
              padding: "8px 14px"
            }}
          >
            <Flex flexDirection={"column"} sx={{ gap: "10px" }}>
              {categoryOptions.map((category, idx) => (
                <PostCategoryItem
                  key={`post-category-${idx}`}
                  option={category}
                  isSelected={selectedCategories.includes(category.value)}
                  onChange={handleChange}
                />
              ))}
            </Flex>
          </SimpleBar>
        </MenuContainer>
      </Popper>
    </PostCategoryButton>
  );
};

export default PostCategorySelector;

import { createGlobalStyle } from "styled-components";

import reset from "styled-reset";
import { Facebook, Instagram, Twitter, Linkedin } from "styled-icons/feather";
import {
  Facebook as FaFacebook,
  Instagram as FaInstagram,
  Twitter as FaTwitter,
  Linkedin as FaLinkedin
} from "styled-icons/fa-brands";
export const colors = {
  brandBlue: "#003399",
  lightBlue: "#e6efff",
  blue: "#0063fb",
  darkBlue: "#1b58ca",
  lightYellow: "rgba(255, 176, 43, 0.2)",
  yellow: "#FFB02B",
  darkYellow: "#d97706",
  deepYellow: "#ffa000",
  orange: "#FF5F00",
  deepOrange: "#EC742C",
  orangeSubtle: "#FFDFCC",
  darkBlack: "#000000",
  black: "#1C1E1F",
  darkgray: "#454A4D",
  gray: "#646769",
  red: "#E65050",
  rebSubtle: "#FFE7E7",
  white: "#ffffff",
  facebookBlue: "#3B5998",
  twitterBlue: "#0084b4",
  linkedInBlue: "#0077B5",
  instagramRed: "#e4405f",
  lightGray: "#808082",
  //new colors
  primary: "rgba(0, 99, 251, 1)",
  primaryFocus: "#004FC9",
  primarySubtle: "rgba(230, 239, 255, 1)",
  primaryLight: "rgba(0, 99, 251, 0.16)",
  primaryHover: "rgba(0, 89, 226, 1)",
  primaryActive: "rgba(0, 79, 201, 1)",
  primaryTransparent: "rgba(0, 99, 251, 0)",
  tertiaryHover: "rgba(0, 99, 251, 0.1)",
  tertiaryActive: "rgba(0, 99, 251, 0.2)",
  greenActive: "#008037",
  greenHover: "#00a648",
  greenFocus: "#007A34",
  green: "#009841",
  greenSubtle: "#E6F5EC",
  greenSubtleDark: "#CCEAD9",
  leafGreen: "#00CC66",
  danger: "#FF0E0E",
  dangerHover: "#E60D0D",
  dangerActive: "#CC0B0B",
  dangerTransparent: "#FFCFCF",
  purple: "#FF1957",
  grey01: "rgba(96, 116, 128, 0.08)",
  grey02: "rgba(96, 116, 128, 0.12)",
  grey03: "rgba(102, 119, 128, 0.16)",
  grey04: "rgba(102, 119, 128, 0.2)",
  grey05: "rgba(247, 249, 250, 1)",
  grey06: "rgba(122, 122, 122, 1)",
  grey07: "rgba(228, 228, 228, 1)",
  grey08: "rgba(182, 182, 182, 1)",
  text01: "#1C1E1F",
  text02: "#646769",
  text03: "#808082",
  darkText01: "rgba(243, 244, 245, 0.95)",
  darkText03: "rgba(243, 244, 245, 0.62)",
  text04: "rgba(60, 63, 66, 0.40)",
  text05: "#ffffff",
  textDisabled: "#bcbcbc",
  iconFill1: "rgba(28, 30, 31, 1)",
  iconFill2: "rgba(100, 103, 105, 1)",
  iconFill3: "rgba(60, 63, 66, 0.45)",
  iconFill4: "#0063FB",
  backdrop: "rgba(28, 30, 31, 0.4)",
  background1: "#ffffff",
  background2: "#fdfdfd",
  background3: "#F7F9FA",
  thumbnails: ["#BDE2EB", "#FDDEB9", "#C1E0D2"],
  linkBlueHover: "#0059E2",
  linkBlueActive: "#004FC9",
  socialHealth: {
    consistency: "#0063fb",
    employeeEngagement: "#f8bc3b",
    contentMix: "#ff7557"
  },
  status: {
    red: "#FF0E0E",
    green: "#009841"
  }
};

export const SIZES = {
  xsmall: { min: 0, max: 767 },
  small: { min: 768, max: 991 },
  medium: { min: 992, max: 1200 },
  large: { min: 1200, max: Infinity }
};

export const SIZES_WIDTH = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px"
};

export const SIZES_HEIGHT = {
  mobileS: "667px",
  mobileM: "864px",
  mobileL: "869px",
  tablet: "1024px",
  laptop: "768px",
  laptopM: "887px",
  laptopL: "1080px"
};

export const DEVICES = {
  mobileS: `(max-width: ${SIZES_WIDTH.mobileS})`,
  mobileM: `(max-width: ${SIZES_WIDTH.mobileM})`,
  mobileL: `(max-width: ${SIZES_WIDTH.mobileL})`,
  tablet: `(max-width: ${SIZES_WIDTH.tablet})`,
  laptop: `(max-width: ${SIZES_WIDTH.laptop})`,
  desktop: `(max-width: ${SIZES_WIDTH.desktop})`
};

export const DEVICES_HEIGHT = {
  mobileS: `(max-height: ${SIZES_HEIGHT.mobileS})`,
  mobileM: `(max-height: ${SIZES_HEIGHT.mobileM})`,
  mobileL: `(max-height: ${SIZES_HEIGHT.mobileL})`,
  tablet: `(max-height: ${SIZES_HEIGHT.tablet})`,
  laptop: `(max-height: ${SIZES_HEIGHT.laptop})`,
  laptopM: `(max-height: ${SIZES_WIDTH.laptopM})`,
  laptopL: `(max-height: ${SIZES_HEIGHT.laptopL})`
};

export const ZINDEX = {
  sky: 999,
  ceil: 99,
  z25: 25,
  z20: 20,
  z15: 15,
  z10: 10,
  z5: 5,
  ground: 1,
  underground: -1
};

export const fonts = {
  headline:
    "'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;",
  body: "Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;"
};

export const media = {
  between(smallKey, largeKey, excludeLarge = false) {
    if (excludeLarge) {
      return `@media (min-width: ${
        SIZES[smallKey].min
      }px) and (max-width: ${SIZES[largeKey].min - 1}px)`;
    } else {
      if (SIZES[largeKey].max === Infinity) {
        return `@media (min-width: ${SIZES[smallKey].min}px)`;
      } else {
        return `@media (min-width: ${SIZES[smallKey].min}px) and (max-width: ${SIZES[largeKey].max}px)`;
      }
    }
  },

  greaterThan(key) {
    return `@media (min-width: ${SIZES[key].min}px)`;
  },

  lessThan(key) {
    return `@media (max-width: ${SIZES[key].min - 1}px)`;
  },

  size(key) {
    const size = SIZES[key];

    if (size.min == null) {
      return media.lessThan(key);
    } else if (size.max == null) {
      return media.greaterThan(key);
    } else {
      return media.between(key, key);
    }
  }
};

export const iconStyles = {
  FACEBOOK: {
    color: colors.facebookBlue,
    iconClass: Facebook,
    logoClass: FaFacebook
  },
  INSTAGRAM: {
    color: colors.instagramRed,
    iconClass: Instagram,
    logoClass: FaInstagram
  },
  LINKEDIN: {
    color: colors.linkedInBlue,
    iconClass: Linkedin,
    logoClass: FaLinkedin
  },
  TWITTER: {
    color: colors.twitterBlue,
    iconClass: Twitter,
    logoClass: FaTwitter
  }
};

export const theme = {
  colors: {
    primary: colors.blue,
    text: colors.black,
    background: "#F7F8F9",
    ...colors
  },
  shadows: {
    shadow01: "0 3px 6px rgba(0, 0, 0, 0.12)",
    shadow02: `0 1px 1px rgba(0, 0, 0, 0.03),
      0 2px 4px ${colors.grey01},
      0 5px 10px ${colors.grey01}`,
    shadow03: `0 1px 8px rgba(0, 0, 0, 0.05),
      0 5px 20px rgba(0, 0, 0, 0.08)`,
    shadow04: `0px 4px 4px 0px rgba(0, 0, 0, 0.25)`,
    shadowXS: `0px 0px 0px 1px rgba(28, 30, 31, 0.05),
      0px 3px 6px rgba(28, 30, 31, 0.05)`,
    shadowM: `0px 0px 0px 1px #1C1E1F0D, 0px 18px 27px -5px #1C1E1F26,
      0px 8px 16px -8px #1C1E1F1F`,
    shadowXL: `0px 30px 60px -30px rgba(0, 0, 0, 0.15),
      0px 50px 100px -20px rgba(0, 0, 0, 0.2),
      0px 0px 1px rgba(0, 0, 0, 0.08)`,
    shadowS: `0px 4px 24px rgba(28, 30, 31, 0.08),
      0px 1px 2px rgba(28, 30, 31, 0.04)`
  },
  breakpoints: ["850px", "1100px", "64em", "570px"],
  stdBreakpoints: {
    xs: "479px",
    sm: "767px",
    m: "990px",
    lg: "1200px",
    xl: "1440px"
  },
  space: [],
  font: "Inter, Lato, sans-serif",
  layout: {
    menuWidth: "244px",
    menuWidthCollapsed: "64px"
  },
  textStyles: {
    h4: {
      lineHeight: "20px",
      fontWeight: 600,
      fontSize: "16px",
      letterSpacing: "-0.02em"
    },
    h5: {
      lineHeight: "24px",
      fontWeight: 700,
      fontSize: "16px",
      letterSpacing: "-0.02em"
    },
    h6: {
      lineHeight: "21px",
      fontWeight: 700,
      fontSize: "14px",
      letterSpacing: "-0.02em"
    }
  },
  fonts,
  variants: {
    primary: {
      fontColor: colors.green,
      color: colors.text05,
      bg: colors.primary,
      hover: {
        bg: colors.primaryHover
      },
      active: {
        bg: colors.primaryActive
      }
    },
    primaryLite: {
      color: colors.text01,
      borderColor: colors.text01,
      bg: colors.white,
      hover: {
        bg: colors.black,
        color: colors.white,
        borderColor: colors.text01
      },
      active: {
        bg: colors.grey03
      }
    },
    primaryWhite: {
      color: colors.text01,
      borderColor: colors.white,
      bg: colors.white,
      hover: {
        bg: colors.white,
        color: colors.white,
        borderColor: colors.text01
      },
      active: {
        bg: colors.white
      }
    },
    secondary: {
      color: colors.text01,
      bg: colors.grey01,
      hover: {
        bg: colors.grey02
      },
      active: {
        bg: colors.grey03
      }
    },
    secondaryQuietAlt: {
      color: colors.text02,
      bg: "transparent",
      hover: {
        bg: colors.grey01,
        color: colors.text01
      },
      active: {
        bg: colors.grey03,
        color: colors.text01
      }
    },
    tertiary: {
      color: colors.primary,
      bg: colors.primaryTransparent,
      hover: {
        bg: colors.tertiaryHover
      },
      active: {
        bg: colors.tertiaryActive
      }
    },
    black: {
      color: colors.text05,
      bg: colors.iconFill1,
      hover: {
        bg: colors.iconFill1
      },
      active: {
        bg: colors.iconFill1
      }
    },
    danger: {
      fontColor: colors.red,
      color: colors.text05,
      bg: colors.danger,
      hover: {
        bg: colors.dangerHover
      },
      active: {
        bg: colors.dangerActive
      }
    },
    dangerLite: {
      fontColor: colors.red,
      color: colors.red,
      bg: "transparent",
      hover: {
        bg: colors.dangerTransparent
      },
      active: {
        bg: colors.dangerTransparent
      }
    },
    success: {
      fontColor: colors.green,
      color: colors.text05,
      bg: colors.green,
      hover: {
        bg: colors.greenHover
      },
      active: {
        bg: colors.greenActive
      }
    },
    neutralLite: {
      color: colors.text02,
      borderColor: colors.grey04,
      bg: colors.white,
      hover: {
        bg: colors.white,
        color: colors.green,
        borderColor: colors.green
      },
      active: {
        bg: colors.greenSubtle
      }
    },
    successLite: {
      color: colors.green,
      borderColor: colors.green,
      bg: colors.greenSubtle,
      hover: {
        bg: colors.greenSubtleDark,
        color: colors.green,
        borderColor: colors.green
      },
      active: {
        bg: colors.greenSubtleDark
      }
    },
    secondaryQuiet: {
      color: colors.text01,
      bg: "transparent",
      hover: {
        bg: colors.grey02
      },
      active: {
        bg: colors.grey03
      }
    }
  }
};

export const GlobalStyle = createGlobalStyle`
  ${reset}
  
  body {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI",
             Roboto, Oxygen-Sans, Ubuntu, Cantarell,
             "Helvetica Neue", sans-serif;
    font-size: 14px;
    color: ${props => props.theme.colors.black};
    background-color: #fff; 
    margin: 0;
  }
  
  h1 {
    font-size: 18px;
    font-weight: bold;
  }
  
  h2 {
    font-weight: 600;
    line-height: 19px;
    font-size: 14px;
    color: ${props => props.theme.colors.black};
    margin-bottom: 20px;
  }
  
  p {
    line-height: 20px;
    font-size: 14px;
    margin-bottom: 20px;
    color: ${props => props.theme.colors.text01};

    & li, & ul {
      color: inherit
    }

    & li {
      list-style-type: disc;
      list-style-position: outside;
      margin-left: 1rem;
      padding-left: 0.5rem;
      margin-block-start: 0.15rem;
      margin-block-end: 0.15rem;
    }
  }
  
  ol {
    list-style-type: decimal;
    list-style-position: inside;
    margin-bottom: 20px;
  }
  
  li {
    line-height: 20px;
    font-size: 14px;
    color: ${props => props.theme.colors.gray}
  }
  
  strong {
    font-weight: 600;
    //color: ${props => props.theme.colors.black}
  }

  hr {
    margin: 8px 0;
    border: none;
    height: 1px;
    background-color: ${props => props.theme.colors.grey02};
  }
  
  a, a:hover {
    color: ${props => props.theme.colors.blue};
    text-decoration: none;
  }
  
  #hubspot-messages-iframe-container {
    bottom: 60px !important;
  }
  
  .react-tooltip-absolute-container{
    z-index: 10;
  }

  .react-tooltip-2 {
    z-index: 8;
  }

  .overflow-hidden {
    overflow: hidden !important;
  }

  .popper-element {
    .popper-arrow,
    .popper-arrow::before {
      position: absolute;
      width: 8px;
      height: 8px;
      background: inherit;
    }

    .popper-arrow {
      visibility: hidden;

      &::before {
        visibility: visible;
        content: "";
        transform: rotate(45deg);
      }
    }

    &[data-popper-placement^="top"] > .popper-arrow {
      bottom: -4px;
    }

    &[data-popper-placement^="bottom"] > .popper-arrow {
      top: -4px;
    }

    &[data-popper-placement^="left"] > .popper-arrow {
      right: -4px;
    }

    &[data-popper-placement^="right"] > .popper-arrow {
      left: -4px;
    }
  }
`;

import styled from "styled-components";
import SimpleBar from "simplebar-react";
import { useDispatch } from "react-redux";
import Popper from "components/common/Popper";
import React, { useEffect, useState } from "react";
import { Flex, Box } from "rebass/styled-components";
import { ChevronDown } from "styled-icons/boxicons-regular";
import { AlertTriangle } from "@styled-icons/evaicons-solid/AlertTriangle";

import Button from "./Button";
import IconButton from "./IconButton";
import Checkbox from "./form/Checkbox";
import { openModal } from "state/actions/ModalActions";
import { Avatar, ChannelAvatar, Service } from "./styles";
import defaultAvatar from "assets/images/dashboard/avatar-empty-state.png";
import { MenuContainer, MenuDivider, MenuFooter } from "../navigation/Menu";

const MAX_CHANNEL_DISPLAY = 4;

export const ChannelToggleContainer = styled(Flex)`
  align-items: center;
  background-color: ${props =>
    props.highlighted ? props.theme.colors.grey02 : "transparent"};
  border-radius: 48px;
  flex-direction: row-reverse;
  gap: ${props => props.gap}px;
  padding: 2px;
  margin-right: 8px;
  cursor: pointer;

  .chevron {
    visibility: ${props => (props.showDropdown ? "visible" : "hidden")};
  }

  &:hover {
    background-color: ${props => props.theme.colors.grey02};

    .chevron {
      visibility: visible;
    }
  }
`;

const ChannelOverflow = styled(Flex)`
  align-items: center;
  background-color: ${props => props.theme.colors.text01};
  border-radius: 32px;
  color: white;
  font-weight: 700;
  font-size: 13px;
  height: 32px;
  justify-content: center;
  width: 32px;
`;

const ChannelToggleButton = styled(IconButton)`
  background-color: transparent;

  &:hover {
    background-color: transparent;
  }
`;

const ChannelSelectItem = styled(Box)`
  align-items: center;
  border-radius: 12px;
  cursor: pointer;
  display: grid;
  grid-template-columns: 20px 32px 1fr auto;
  grid-gap: 12px;
  margin: 0 8px;
  padding: 8px;
  width: 320px;

  &:hover {
    background-color: ${props => props.theme.colors.grey01};

    ${props =>
      props.needsReconnection &&
      `
    background-color: ${props.theme.colors.dangerTransparent};
  `}
  }
`;

const ChannelSelectLabel = styled(Flex)`
  padding-left: 6px;
  font-weight: 500;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const ChannelSelectIcon = styled(AlertTriangle)`
  margin-left: 8px;
  cursor: pointer;
`;

function ChannelSelect({ channel, isSelected, onChange, onIconClick }) {
  return (
    <ChannelSelectItem
      onClick={() => onChange(channel.id)}
      needsReconnection={channel.needsReconnection}
    >
      <Checkbox
        type={"checkbox"}
        onChange={() => onChange(channel.id)}
        checked={isSelected}
      />
      <ChannelAvatar key={channel.id} mr={0} enabled={true} clickable={false}>
        <Avatar
          src={channel.avatar ?? defaultAvatar}
          width={28}
          height={28}
          noShadow={true}
          isRounded={true}
          onError={event => {
            event.target.src = defaultAvatar;
            event.onerror = null;
          }}
        />
        <Service
          size={16}
          key={channel.id}
          title={channel.username}
          type={channel.service}
        />
      </ChannelAvatar>
      <ChannelSelectLabel>
        {channel.username}
        {channel.needsReconnection && (
          <ChannelSelectIcon
            size={20}
            color="red"
            onClick={e => {
              e.stopPropagation();
              onIconClick();
            }}
          />
        )}
      </ChannelSelectLabel>
    </ChannelSelectItem>
  );
}

function groupChannels(channels) {
  let channelGroup = {
    genericChannels: [],
    linkedInChannels: []
  };
  for (let channel of channels) {
    if (channel.service === "linkedin" && channel.serviceType === "profile")
      channelGroup.linkedInChannels.push(channel);
    else channelGroup.genericChannels.push(channel);
  }

  return channelGroup;
}

export default function ChannelToggle({
  channels,
  selectedIds = [],
  onSave,
  onChange,
  adjustPosition = true,
  gap = 7,
  showDropdown = false
}) {
  const dispatch = useDispatch();
  const [locallySelected, setLocallySelected] = useState(selectedIds);

  const { genericChannels, linkedInChannels } = groupChannels(channels);

  const [referenceElement, setReferenceElement] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const toggleShowOptions = () => setShowOptions(!showOptions);

  const handleToggleChannel = targetId => {
    if (locallySelected.includes(targetId)) {
      const selected = locallySelected.filter(
        channelId => channelId !== targetId
      );

      setLocallySelected(selected);
      onChange && onChange(selected);
    } else {
      setLocallySelected([...locallySelected, targetId]);
      onChange && onChange([...locallySelected, targetId]);
    }
  };

  const selectAll = () => {
    setLocallySelected(channels.map(channel => channel.id));
    onChange && onChange(channels.map(channel => channel.id));
  };

  const unselectAll = () => {
    setLocallySelected([]);
    onChange && onChange([]);
  };

  const handleSave = () => {
    onSave(locallySelected);
    setShowOptions(false);
  };

  const handleIconClick = () => {
    toggleShowOptions();
    dispatch(openModal("CHANNEL_RECONNECT_MODAL"));
  };

  // Cleanup
  useEffect(() => {
    return () => {
      setLocallySelected(selectedIds);
    };
  }, [showOptions, selectedIds]);

  const [tooltipOffset, setTooltipOffset] = useState([-120, 0]);

  useEffect(() => {
    if (adjustPosition) {
      setTooltipOffset([
        -320 + (Math.min(selectedIds.length, MAX_CHANNEL_DISPLAY + 1) + 1) * 32,
        0
      ]);
    }
  }, [selectedIds, adjustPosition]);

  return (
    <Box ref={setReferenceElement}>
      <ChannelToggleContainer
        gap={gap}
        highlighted={false}
        showDropdown={showDropdown}
        onClick={toggleShowOptions}
      >
        <ChannelToggleButton
          className="chevron"
          size={28}
          padding={2}
          icon={ChevronDown}
        />
        {selectedIds.length > MAX_CHANNEL_DISPLAY && (
          <ChannelOverflow>
            +{selectedIds.length - MAX_CHANNEL_DISPLAY}
          </ChannelOverflow>
        )}
        {channels
          .filter(c => selectedIds.includes(c.id))
          .slice(0, MAX_CHANNEL_DISPLAY)
          .map(channel => (
            <ChannelAvatar key={channel.id} ml={2} enabled={true} clickable>
              <Avatar
                src={channel.avatar ?? defaultAvatar}
                width={28}
                height={28}
                noShadow={true}
                isRounded={true}
                onError={event => {
                  event.target.src = defaultAvatar;
                  event.onerror = null;
                }}
              />
              <Service
                size={16}
                key={channel.id}
                title={channel.username}
                type={channel.service}
              />
            </ChannelAvatar>
          ))}
      </ChannelToggleContainer>
      <Popper
        offset={tooltipOffset}
        referenceElement={referenceElement}
        visible={showOptions}
        onClose={() => setShowOptions(false)}
        exceptions={[referenceElement]}
      >
        <MenuContainer width={336}>
          <SimpleBar
            style={{ maxHeight: 420, width: 336, overflowX: "hidden" }}
          >
            {genericChannels.map(channel => (
              <ChannelSelect
                key={channel.id}
                channel={channel}
                isSelected={locallySelected.includes(channel.id)}
                onChange={handleToggleChannel}
                onIconClick={handleIconClick}
              />
            ))}
            {linkedInChannels.length > 1 && genericChannels.length > 0 && (
              <MenuDivider />
            )}
            {linkedInChannels.map(channel => (
              <ChannelSelect
                key={channel.id}
                channel={channel}
                isSelected={locallySelected.includes(channel.id)}
                onChange={handleToggleChannel}
                onIconClick={handleIconClick}
              />
            ))}
          </SimpleBar>

          <MenuFooter>
            <Flex>
              <Button size={"sm"} variant={"tertiary"} onClick={selectAll}>
                Select all
              </Button>
              <Button size={"sm"} variant={"tertiary"} onClick={unselectAll}>
                Unselect all
              </Button>
            </Flex>
            {onSave && (
              <Button
                size={"sm"}
                onClick={handleSave}
                disabled={locallySelected.length === 0}
              >
                Save
              </Button>
            )}
          </MenuFooter>
        </MenuContainer>
      </Popper>
    </Box>
  );
}

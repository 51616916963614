import React from "react";
import styled from "styled-components";
import { BLOCKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Paragraph, Headline2 } from "./styles.js";

const ListItem = styled.li`
  list-style-type: disc;
  margin-left: 16px;

  & p {
    margin-bottom: 12px;
  }
`;

const StyledHeadline2 = styled(Headline2)`
  margin: 24px 0 16px;
`;

/* eslint-disable */
const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Paragraph>{children}</Paragraph>,
    [BLOCKS.HEADING_2]: (node, children) => (
      <StyledHeadline2 marginTop={24}>{children}</StyledHeadline2>
    ),
    [BLOCKS.LIST_ITEM]: (node, children) => <ListItem>{children}</ListItem>
  }
};
/* eslint-enable */

function ContentfulDocument({ src }) {
  const parsedDoc = documentToReactComponents(src, options);

  return <>{parsedDoc}</>;
}

export default ContentfulDocument;

import _ from "lodash";

import store from "state/store";
import {
  addDraft,
  removeDraftById,
  changeDraftCountByValue
} from "state/actions/DraftActions";
import { capitalize } from "utils/string";
import { callApi } from "utils/ContentoApi";
import { SERVICES } from "constants/services";
import { ITag } from "contextApi/composerContext";
import { ICaption, IPostConcept } from "contextApi/composerContext";
import { trackAnalyticsEvent } from "state/actions/AnalyticsActions";
import { difference, removeTimezoneFromDate, today } from "utils/date";

export enum PostEvent {
  POST_TO_DRAFT = "POST_TO_DRAFT",
  CREATE_DRAFT = "CREATE_DRAFT",
  UPDATE_DRAFT = "UPDATE_DRAFT",
  DRAFT_TO_POST = "DRAFT_TO_POST",
  UPDATE_POST = "UPDATE_POST",
  CREATE_POST = "CREATE_POST",
  UPDATE_POST_GROUP = "UPDATE_POST_GROUP",
  POST_GROUP_TO_DRAFT = "POST_GROUP_TO_DRAFT"
}

interface IPostEventData {
  params: {
    method: string;
    url: string;
    data: any;
  };
  analyticsEvent: string;
  successToast: string;
  errorToast: string;
}

const omitPostPropertiesForDraft = (post: any) => {
  return _.omit(post, ["status", "isDraft", "scheduleTime", "isStuck"]);
};

const omitPostPropertiesForPost = (post: any) => {
  return _.omit(
    {
      ...post,
      caption: _.omit(post.caption, ["all"])
    },
    ["isPostGroup", "isStuck", "isDraft"]
  );
};

const constructPostToDraftEventData = (post: any): IPostEventData => {
  const postData = omitPostPropertiesForDraft(post);

  return {
    params: {
      method: "post",
      url: `/accounts/${postData.accountId}/posts/${postData.id}/draft-posts`,
      data: postData
    },
    analyticsEvent: "Post to Draft",
    successToast: "Post converted to Draft",
    errorToast: "There was an error when converting the post to a draft"
  };
};

const constructCreateDraftEventData = (post: any): IPostEventData => {
  const postData = omitPostPropertiesForDraft(post);

  return {
    params: {
      method: "post",
      url: `/accounts/${postData.accountId}/draft-posts`,
      data: postData
    },
    analyticsEvent: "Created Draft",
    successToast: "Draft created",
    errorToast: "There was an error creating the draft"
  };
};

const constructUpdateDraftEventData = (post: any): IPostEventData => {
  const postData = omitPostPropertiesForDraft(post);

  return {
    params: {
      method: "put",
      url: `/accounts/${postData.accountId}/draft-posts/${postData.id}`,
      data: postData
    },
    analyticsEvent: "Updated Draft",
    successToast: "Draft updated",
    errorToast: "There was an error updating the draft"
  };
};

const constructDraftToPostEventData = (post: any): IPostEventData => {
  const postData = omitPostPropertiesForPost(post);

  postData.draftId = post.id;
  _.unset(postData, "id");

  return {
    params: {
      method: "post",
      url: `/posts`,
      data: postData
    },
    analyticsEvent: "Draft to Post",
    successToast: `Post ${postData.status.toLowerCase()}`,
    errorToast: "There was an error when converting the draft to a post"
  };
};

const constructUpdatePostEventData = (post: any): IPostEventData => {
  const postData = omitPostPropertiesForPost(post);

  return {
    params: {
      method: "put",
      url: `/posts/${postData.id}`,
      data: postData
    },
    analyticsEvent: "Updated Post",
    successToast: `Post ${postData.status.toLowerCase()}`,
    errorToast: "There was an error updating the post"
  };
};

const constructCreatePostEventData = (post: any): IPostEventData => {
  const postData = omitPostPropertiesForPost(post);

  return {
    params: { method: "post", url: `/posts`, data: postData },
    analyticsEvent: "Created Post",
    successToast: `Post ${postData.status.toLowerCase()}`,
    errorToast: "There was an error creating the post"
  };
};

const constructUpdatePostGroupEventData = (post: any): IPostEventData => {
  const postData = omitPostPropertiesForPost(post);

  postData.groupId = post.id;
  _.unset(postData, "id");

  return {
    params: {
      method: "put",
      url: `/accounts/${postData.accountId}/post-groups/${postData.groupId}`,
      data: postData
    },
    analyticsEvent: "Updated Post Group",
    successToast: `Post Group updated`,
    errorToast: "There was an error updating the post group"
  };
};

const constructPostGroupToDraftEventData = (post: any): IPostEventData => {
  const postData = omitPostPropertiesForPost(post);

  return {
    params: {
      method: "put",
      url: `/accounts/${postData.accountId}/draft-posts/post-groups/${postData.id}`,
      data: postData
    },
    analyticsEvent: "Post Group to Draft",
    successToast: `Post Group converted to Draft`,
    errorToast: "There was an error when converting the post group to a draft"
  };
};

const constructPostEventData = (
  postEvent: PostEvent,
  post: any
): IPostEventData => {
  switch (postEvent) {
    case PostEvent.POST_TO_DRAFT:
      return constructPostToDraftEventData(post);
    case PostEvent.CREATE_DRAFT:
      return constructCreateDraftEventData(post);
    case PostEvent.UPDATE_DRAFT:
      return constructUpdateDraftEventData(post);
    case PostEvent.DRAFT_TO_POST:
      return constructDraftToPostEventData(post);
    case PostEvent.UPDATE_POST:
      return constructUpdatePostEventData(post);
    case PostEvent.CREATE_POST:
      return constructCreatePostEventData(post);
    case PostEvent.UPDATE_POST_GROUP:
      return constructUpdatePostGroupEventData(post);
    case PostEvent.POST_GROUP_TO_DRAFT:
      return constructPostGroupToDraftEventData(post);
  }
};

export const getEnabledServicesWithType = (
  selectedChannels: string[],
  accountChannels: any
): any => {
  return _.uniq(
    selectedChannels.map(id => {
      const channel = accountChannels.find((channel: any) => channel.id === id);
      return { service: channel?.service, serviceType: channel?.serviceType };
    })
  );
};

export const getEnabledServices = (
  selectedChannels: string[],
  accountChannels: any
): string[] => {
  const enabledServicesWithType = getEnabledServicesWithType(
    selectedChannels,
    accountChannels
  );

  return _.uniq(enabledServicesWithType.map((s: any) => s.service));
};

export const constructPostData = (post: any) => {
  const account = store.getState().account.data;

  let postData = {
    ...post,
    accountId: account.id
  };

  // Remove captions for inactive channels
  const enabledServices = getEnabledServices(
    postData.channels,
    account.channels
  );
  const inactiveServices = _.difference(
    Object.values(SERVICES),
    enabledServices
  );
  postData.caption = _.omit(post.caption, [...inactiveServices]);

  // Remove unwanted property from attachment
  if (postData.attachment) {
    postData.attachment = _.omit(post.attachment, ["embeddable"]);
  }

  // Combine the social mentions of all the channels into a single array
  if (postData.socialMentions) {
    postData.mentions = _.uniqWith(
      _.flatten(
        Object.values(
          _.omit(postData.socialMentions, ["all", ...inactiveServices])
        )
      ),
      _.isEqual
    );
    _.unset(postData, "socialMentions");
  }

  // Remove timezone from the scheduled date
  if (postData.scheduledAt) {
    const scheduledAt =
      postData.scheduledAt instanceof Date
        ? postData.scheduledAt
        : new Date(postData.scheduledAt);

    postData.scheduledAt = removeTimezoneFromDate(scheduledAt);
  }

  return postData;
};

export const createUpdatePost = (
  postEvent: PostEvent,
  postData: any,
  successCallback: (response: any, toastMsg?: string) => void,
  errorCallback: (toastMsg?: string) => void,
  eventParams: { [key: string]: any } = {}
) => {
  const { params, errorToast, successToast } = constructPostEventData(
    postEvent,
    postData
  );
  const { data } = params;

  callApi(params)
    .then(async response => {
      // currently the update of the articles on ES is async so
      // we end up having cases where the UI ist not updated correctly
      // so adding a 2.5s forced delay seems to fix the issue as by the time the messages
      // and callbacks execute the article on ES is already updated
      if (data.contentId) {
        await new Promise(resolve => setTimeout(resolve, 2500));
      }

      store.dispatch(
        trackAnalyticsEvent("Post Saved", {
          channelType: Object.keys(data.caption).map(c => capitalize(c)),
          channelCount: data.channels.length,
          postType: postData.isDraft ? "Draft" : "Scheduled",
          newPost: !!postData.id,
          groupPost: data.channels.length > 1,
          imageIncluded: data.attachment?.type === "photo" ? "Yes" : "No",
          videoIncluded: data.attachment?.type === "video" ? "Yes" : "No",
          pdfIncluded: data.attachment?.type === "pdf" ? "Yes" : "No",
          instagramReels: !!data.attachment?.options?.isReel,
          category: data.postIdea?.title ?? data.contentTypeId,
          taggedUsers: !!(data.mentions ?? []).length,
          newsArticle: !!data.contentId,
          scheduledDeltaDays:
            (parseFloat(
              (
                difference(new Date(data.scheduledAt), today(), "hours") / 24
              ).toFixed(1)
            ) *
              10) /
            10,
          ...eventParams
        })
      );

      if (!postData.id && !!data.contentId) {
        store.dispatch(trackAnalyticsEvent("Article Posted"));
      }

      successCallback(response, successToast);
    })
    .catch(error => {
      let errorMessage = "Please try again later.";
      if (error.name === "ValidationError") {
        errorMessage = error.message;
      }
      console.error(error);
      errorCallback(`${errorToast}: ${errorMessage}`);
    });
};

export const ungroupPost = async (groupId: string): Promise<string> => {
  const account = store.getState().account.data;

  try {
    await callApi({
      method: "post",
      url: `/accounts/${account.id}/post-groups/${groupId}/ungroup`
    });

    store.dispatch(trackAnalyticsEvent("Post ungrouped"));

    return Promise.resolve("Successfully ungrouped post.");
  } catch (error) {
    console.error(error);
    throw new Error("Post could not be ungrouped.");
  }
};

export const approveDraft = async (post: any): Promise<string> => {
  const account = store.getState().account.data;

  try {
    const response = await callApi({
      method: "post",
      url: `/accounts/${account.id}/draft-posts/${post.id}/posts`
    });

    store.dispatch(changeDraftCountByValue(-1));
    store.dispatch(removeDraftById(post.id));

    store.dispatch(trackAnalyticsEvent("Draft to Post"));

    const postStatus = response.posts[0].status;
    return Promise.resolve(`Post ${postStatus.toLowerCase()}`);
  } catch (error) {
    console.error(error);
    throw new Error("There was an error when converting the draft to a post.");
  }
};

export const postGroupToDraft = async (groupId: string): Promise<string> => {
  const account = store.getState().account.data;

  try {
    const draft = await callApi({
      method: "post",
      url: `/accounts/${account.id}/draft-posts/post-groups/${groupId}`
    });

    store.dispatch(changeDraftCountByValue(1));
    store.dispatch(addDraft({ ...draft, isDraft: true }));

    store.dispatch(trackAnalyticsEvent("Post Group to Draft"));

    return Promise.resolve("Successfully converted post to draft.");
  } catch (error) {
    console.error(error);
    throw new Error("Post could not be converted to Draft.");
  }
};

export const postToDraft = async (postId: string): Promise<string> => {
  const account = store.getState().account.data;

  try {
    const draft = await callApi({
      method: "post",
      url: `/accounts/${account.id}/posts/${postId}/draft-posts`,
      params: {
        "load-existing": "true"
      }
    });

    store.dispatch(changeDraftCountByValue(1));
    store.dispatch(addDraft({ ...draft, isDraft: true }));

    store.dispatch(trackAnalyticsEvent("Post to Draft"));

    return Promise.resolve("Successfully converted post to draft.");
  } catch (error) {
    console.error(error);
    throw new Error("Post could not be converted to Draft.");
  }
};

export const postGroupDelete = async (groupId: string): Promise<string> => {
  const account = store.getState().account.data;

  try {
    await callApi({
      method: "delete",
      url: `/accounts/${account.id}/post-groups/${groupId}`
    });

    store.dispatch(trackAnalyticsEvent("Deleted Post Group"));

    return Promise.resolve("Successfully deleted post.");
  } catch (error) {
    console.error(error);
    throw new Error("Post could not be deleted.");
  }
};

export const fetchAvailableTags = async (): Promise<ITag[]> => {
  const account = store.getState().account.data;

  try {
    const tags = await callApi({
      method: "get",
      url: `/accounts/${account.id}/tags`
    });

    return tags;
  } catch (error) {
    console.error(error);
    throw new Error("Tags could not be fetched.");
  }
};

export const scrapeUrlLink = async (
  url: string,
  linkOnly: boolean = false
): Promise<any> => {
  const account = store.getState().account.data;

  try {
    const urlInfo = await callApi({
      method: "get",
      url: "scrape",
      params: {
        url,
        accountId: account.id,
        linkOnly
      }
    });

    return urlInfo;
  } catch (error) {
    console.error(error);
    throw new Error(`We were unable to process the URL: ${url}`);
  }
};

export const fetchPostConcepts = async (): Promise<IPostConcept[]> => {
  try {
    const postIdeas = await callApi({
      method: "get",
      url: `/post-ideas`
    });

    const postConceptsAndIdeas = _.flatMap(
      (postIdeas as any[]).reduce(
        (acc: { [key: string]: IPostConcept }, postIdea) => {
          acc[postIdea.postConcept.id] = {
            ...(acc[postIdea.postConcept.id] ?? postIdea.postConcept),
            postIdeas: [
              ...(acc[postIdea.postConcept.id]?.postIdeas ?? []),
              {
                id: postIdea.id,
                title: postIdea.title,
                subtitle: postIdea.subtitle,
                captions: postIdea.captions || []
              }
            ]
          };
          return acc;
        },
        {}
      )
    );

    const order = [
      "educational",
      "entertain",
      "personal",
      "promotional",
      "employees"
    ];
    const orderedPostConcepts = _.sortBy(
      _.flatMap(postConceptsAndIdeas),
      idea => {
        return order.findIndex(item => item === idea.slug);
      }
    );

    return orderedPostConcepts;
  } catch (error) {
    console.error(error);
    throw new Error("Post concepts could not be fetched.");
  }
};

export const fetchCaptionsByPostIdea = async (
  postIdea: string
): Promise<ICaption[]> => {
  const account = store.getState().account.data;

  try {
    const captions = await callApi({
      method: "get",
      url: `/accounts/${account.id}/caption-suggestions`,
      params: {
        postIdea
      }
    });

    const languageMappedCaptions = captions.reduce(
      (acc: ICaption[], caption: any) => {
        acc.push({
          id: caption.id,
          title: caption.title,
          caption:
            caption.caption[
              account.language.toLowerCase() === "nl" ? "nl" : "en-US"
            ]
        });

        return acc;
      },
      []
    );

    return languageMappedCaptions;
  } catch (error) {
    console.error(error);
    throw new Error("Captions could not be fetched.");
  }
};

export const fetchImageSuggestions = async (
  query: string = "",
  useNlp: boolean = true
): Promise<any[]> => {
  const account = store.getState().account.data;

  try {
    if (query.length <= 0) {
      const photos = await callApi({
        method: "get",
        url: `/accounts/${account.id}/unsplash/random`,
        params: {
          query
        }
      });

      return photos;
    } else if (useNlp) {
      const { photos } = await callApi({
        method: "post",
        url: "/nlp/get-images-for-caption",
        data: {
          caption: query
        }
      });

      return photos;
    } else {
      const photos = await callApi({
        method: "get",
        url: `/accounts/${account.id}/unsplash/photos`,
        params: {
          query
        }
      });

      return photos.results;
    }
  } catch (error) {
    console.error(error);
    // commenting this because errors happen often and we don't want to show them to the user
    // throw new Error("Photos could not be fetched.");

    return [];
  }
};

export const downloadUnsplashImage = async (imageId): Promise<any> => {
  const account = store.getState().account.data;

  const response = await callApi({
    method: "get",
    url: `/accounts/${account.id}/unsplash/download`,
    params: {
      id: imageId
    }
  });
};

export const uploadImageByUrl = async (url: string): Promise<any> => {
  try {
    const uploadedImage = await callApi({
      method: "post",
      url: `images/upload-url`,
      data: {
        url
      }
    });

    return uploadedImage;
  } catch (error) {
    console.error(error);
    throw new Error("Photos could not be fetched.");
  }
};

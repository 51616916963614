import _ from "lodash";
import moment from "moment-timezone";
import { connect } from "react-redux";
import ReactLoading from "react-loading";
import { withRouter } from "react-router-dom";
import { useToaster } from "@hellocontento/maillard";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
  CalendarDay,
  CalendarMonth,
  CalendarDayRow,
  CalendarLoading,
  IconBottonGroup,
  CalendarControls,
  CalendarContainer,
  CalendarDayColumns,
  CalendarHeaderZone,
  CalendarDateControl,
  CalendarDayColumnsContainer
} from "./styles";
import {
  useComposerState,
  useComposerActions
} from "contextApi/composerContext";
import DayColumn from "./DayColumn";
import ContextMenu from "./ContextMenu";
import Button from "components/common/Button";
import IconButton from "components/common/IconButton";
import { useAppActions } from "contextApi/appContext";
import { useContentoApi } from "utils/useContentoApi";
import ScheduleViewToggle from "../ScheduleViewToggle";
import ChannelToggle from "components/common/ChannelToggle";
import { difference, startOf, getWeekNumber } from "utils/date";
import * as activityActions from "state/actions/ActivityActions";
import DroppableWrapper from "../common/drag-and-drop/DroppableWrapper";
import DragDropContextWrapper from "../common/drag-and-drop/DragDropContextWrapper";

function Calendar({ account, activities, menuCollapsed, saveActivities }) {
  const setIsDraftPanelOpen = useAppActions(state => state.setIsDraftPanelOpen);
  const onPostedEvent = useComposerState(state => state.events.onPostedEvent);
  const onReloadEvent = useComposerState(state => state.events.onReloadEvent);
  const onDeletedEvent = useComposerState(state => state.events.onDeletedEvent);
  const createTask = useComposerActions(actions => actions.createTask);

  const [fetchActivities, cancelFetchActivities] = useContentoApi(
    `/accounts/${account.id}/activities/calendar`
  );

  const [loading, setLoading] = useState(true);
  const [weekOffset, setWeekOffset] = useState(0);

  const [selectedChannelIds, setSelectedChannelIds] = useState(
    account.channels.map(channel => channel.id)
  );
  const addToast = useToaster();

  const momentTZ = useCallback(
    date => {
      return moment.tz(date, account.timezone);
    },
    [account.timezone]
  );

  let startDate = useMemo(() => {
    return momentTZ().startOf("isoWeek").add(weekOffset, "week");
  }, [momentTZ, weekOffset]);

  const endDate = useMemo(() => {
    return momentTZ(startDate).add(1, "week");
  }, [momentTZ, startDate]);

  const today = moment();

  const refreshActivities = useCallback(() => {
    const params = {
      fromDate: startDate.toISOString(),
      toDate: endDate.toISOString()
    };

    if (selectedChannelIds.length > 0) {
      params.channelIds = selectedChannelIds.join(",");
    }
    setLoading(true);

    fetchActivities({ params })
      .then(activitiesResult => {
        saveActivities({
          data: activitiesResult,
          params
        });
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);

        if (error.name !== "RequestCancelled") {
          addToast("Could not load activities", "error");
        }
      });
  }, [
    endDate,
    addToast,
    startDate,
    saveActivities,
    fetchActivities,
    selectedChannelIds
  ]);

  useEffect(() => {
    refreshActivities();
    return cancelFetchActivities;
  }, [cancelFetchActivities, refreshActivities]);

  const changeWeek = useCallback(
    offset => {
      if (offset !== weekOffset) {
        setWeekOffset(offset);
      } else {
        refreshActivities();
      }
    },
    [refreshActivities, weekOffset]
  );

  const onPosted = useCallback(
    post => {
      const postData = !!post.posts ? post.posts[0] : post.post ?? post;

      if (!!postData) {
        const activeDate =
          postData.postedAt || postData.scheduledAt
            ? new Date(postData.postedAt || postData.scheduledAt)
            : undefined;

        if (!!activeDate) {
          const offset = difference(
            startOf(activeDate, "week", { weekStartsOn: 1 }),
            startOf(new Date(), "week", { weekStartsOn: 1 }),
            "weeks"
          );

          changeWeek(offset);
        }

        if (!activeDate && postData.isDraft) {
          setIsDraftPanelOpen(true);
        }
      }
    },
    [changeWeek, setIsDraftPanelOpen]
  );

  useEffect(() => onPostedEvent.listen(onPosted), [onPostedEvent, onPosted]);

  useEffect(
    () => onReloadEvent.listen(refreshActivities),
    [onReloadEvent, refreshActivities]
  );

  useEffect(
    () => onDeletedEvent.listen(refreshActivities),
    [onDeletedEvent, refreshActivities]
  );

  const tasks =
    activities
      ?.filter(activity => activity.type === "TASK")
      .map(activity => {
        return {
          ...activity.task,
          isPhantom: activity.isPhantom
        };
      }) ?? [];

  const posts =
    activities
      ?.filter(activity => activity.type === "POST")
      .map(activity => {
        return {
          ...activity.post,
          isPhantom: activity.isPhantom
        };
      }) ?? [];

  const drafts =
    activities
      ?.filter(
        activity =>
          activity.type === "DRAFT_POST" && activity.draft.scheduledAt !== null
      )
      .map(activity => {
        return {
          ...activity.draft,
          isPhantom: activity.isPhantom,
          isDraft: true
        };
      }) ?? [];

  const groups =
    activities
      ?.filter(activity => activity.type === "POST_GROUP")
      .map(activity => {
        return {
          ...activity.group,
          isPostGroup: true,
          isPhantom: activity.isPhantom
        };
      }) ?? [];

  const days = [];

  for (let i = 0; i < 7; i++) {
    const date = momentTZ(startDate).startOf("isoWeek").add(i, "day");

    const isToday = date.isSame(today, "day");
    const isBeforeToday = date.isBefore(today, "day");

    days.push({
      date: date,
      isWeekend: ["Sat", "Sun"].includes(date.format("ddd")),
      isToday,
      isBeforeToday,
      entries: []
    });
  }

  const allPosts = [...posts, ...drafts, ...groups];

  if (!loading) {
    allPosts.forEach(post => {
      let date =
        post.status === "SENT"
          ? momentTZ(post.postedAt)
          : momentTZ(post.scheduledAt);
      let dayOfWeek = date.format("E") - 1;
      let picture;
      if (post.attachment) {
        if (post.attachment.type === "photo") {
          picture = _.isArray(post.attachment.url)
            ? post.attachment.url[0]
            : post.attachment.url;
        } else if (post.attachment.type === "article") {
          picture = post.attachment.image;
        }
      }

      days[dayOfWeek].entries.push({
        id: `post-${post.id}`,
        cardId: post.id,
        type: post.isDraft
          ? "DRAFT_POST"
          : `POST${post.channels?.length > 1 ? "_GROUP" : ""}`,
        dateTime: date,
        time: date.format("HH:mm"),
        channels:
          post.isDraft || post.isPostGroup ? post.channels : [post.channel],
        text:
          post.isDraft || post.isPostGroup
            ? Object.values(post.caption).sort((a, b) => {
                return b.length - a.length;
              })[0]
            : post.caption,
        picture: picture,
        title: post.attachment ? post.attachment.title : undefined,
        post: post,
        isPhantom: post.isPhantom
      });
    });

    //Place tasks
    tasks.forEach(task => {
      const date = momentTZ(task.date);
      const dayOfWeek = date.format("E") - 1;

      days[dayOfWeek].entries.push({
        id: `slot-${task.id}`,
        cardId: task.id,
        type: "TASK",
        dateTime: date,
        time: date.format("HH:mm"),
        channels: [...task.channels],
        task: task,
        isPast: !date.isAfter(momentTZ()),
        isPhantom: task.isPhantom
      });
    });

    // Sort and group entries organization
    days.forEach(day => {
      // day.entries = organizeEntries(day.entries, ungroupedEntries);
      day.entries = day.entries.sort((a, b) => {
        return a.time < b.time ? -1 : 1;
      });
    });
  }

  const monthChange = days[0].date.month() !== days[6].date.month();

  const handleChannelToggle = data => {
    setSelectedChannelIds(data);
  };

  const postsOnWeekend = days.reduce((acc, day) => {
    if (!day.isWeekend) return acc;
    const weekend = moment(day.date).format("ddd");
    acc[weekend] = day.entries.length > 0;
    return acc;
  }, []);

  let gridColumnLayout = `repeat(7, 1fr)`;
  let heightAdjust = "13vw";

  if (!postsOnWeekend["Sat"] && !postsOnWeekend["Sun"]) {
    gridColumnLayout = `repeat(5, 1fr) 90px 90px`;
    heightAdjust = "15.8vw";
  } else if (postsOnWeekend["Sat"] && !postsOnWeekend["Sun"]) {
    gridColumnLayout = `repeat(6, 1fr) 90px`;
    heightAdjust = "13.9vw";
  } else if (!postsOnWeekend["Sat"] && postsOnWeekend["Sun"]) {
    gridColumnLayout = `repeat(5, 1fr) 90px 1fr`;
    heightAdjust = "13.9vw";
  }

  const attachAddNewButton = day => {
    if (day.isBeforeToday) return false;

    const dayOfDate = day.date.format("ddd");

    return (
      !["Sat", "Sun"].includes(dayOfDate) ||
      (dayOfDate === "Sat" && postsOnWeekend["Sat"]) ||
      (dayOfDate === "Sun" && postsOnWeekend["Sun"])
    );
  };

  const getListStyle = isDraggingOver => {
    return {
      background: isDraggingOver
        ? "linear-gradient(180deg, rgba(116, 123, 128, 0.13) 0%, rgba(196, 196, 196, 0.08) 100%)"
        : "white",
      borderRadius: "16px"
    };
  };

  const flatEntries = days.reduce((acc, value) => {
    return [...acc, ...value.entries];
  }, []);

  const weekNumber = getWeekNumber(days[0].date.toDate())
    .toString()
    .padStart(2, "0");

  return (
    <CalendarContainer>
      <CalendarHeaderZone>
        <CalendarDateControl>
          <Button
            size={"sm"}
            variant={"secondary"}
            onClick={() => changeWeek(0)}
          >
            Today
          </Button>
          <IconBottonGroup>
            <IconButton
              icon="icon-arrowleft"
              variant={"secondary"}
              size={36}
              iconSize={24}
              iconColor={"#646769"}
              onClick={() => changeWeek(weekOffset - 1)}
            />
            <IconButton
              icon="icon-arrowright"
              variant={"secondary"}
              size={36}
              iconSize={24}
              iconColor={"#646769"}
              onClick={() => changeWeek(weekOffset + 1)}
            />
          </IconBottonGroup>
          <CalendarMonth>
            <em>
              {days[0].date.format("MMMM")}{" "}
              {monthChange && "- " + days[6].date.format("MMMM")}
            </em>
            &nbsp;{days[0].date.format("YYYY")}
            <span className="week_num">Week {weekNumber}</span>
            <CalendarLoading loading={loading ? 1 : 0}>
              <ReactLoading width={20} height={20} color={"#999"} type="spin" />
            </CalendarLoading>
          </CalendarMonth>
        </CalendarDateControl>
        <ScheduleViewToggle defaultValue="week" />
        <CalendarControls>
          {account.channels.length > 0 && (
            <ChannelToggle
              gap={0}
              adjustPosition={true}
              channels={account.channels}
              selectedIds={selectedChannelIds}
              onSave={handleChannelToggle}
              showDropdown={true}
            />
          )}

          <Button onClick={() => createTask()} isRounded>
            Add tasks
          </Button>
        </CalendarControls>
      </CalendarHeaderZone>
      <DragDropContextWrapper>
        <CalendarDayColumnsContainer menuCollapsed={menuCollapsed}>
          <CalendarDayRow layout={gridColumnLayout}>
            {days.map(day => {
              return (
                <CalendarDay isToday={day.isToday} key={day.date.toISOString()}>
                  <span>
                    {day.date.format("ddd")} <strong>{day.date.date()}</strong>
                  </span>
                  <ContextMenu day={day} />
                </CalendarDay>
              );
            })}
          </CalendarDayRow>

          <CalendarDayColumns layout={gridColumnLayout}>
            {days.map((day, idx) => {
              return (
                <DroppableWrapper
                  key={idx}
                  id={day.date.format("YYYY-MM-DD")}
                  entries={flatEntries}
                  height={heightAdjust}
                  isDisabled={day.isBeforeToday}
                  applyStyling={getListStyle}
                >
                  <DayColumn
                    height={heightAdjust}
                    day={day}
                    isAddNewButtonVisible={attachAddNewButton(day)}
                  />
                </DroppableWrapper>
              );
            })}
          </CalendarDayColumns>
        </CalendarDayColumnsContainer>
      </DragDropContextWrapper>
    </CalendarContainer>
  );
}

const mapStateToProps = state => {
  return {
    account: state.account.data,
    menuCollapsed: state.layout.collapsed,
    activities: state.activity.activities
  };
};

export default withRouter(
  connect(mapStateToProps, {
    saveActivities: activityActions.saveActivities,
    updateActivity: activityActions.updateActivity
  })(Calendar)
);

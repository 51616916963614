import React, { useMemo } from "react";
import styled from "styled-components";

import {
  SecondaryHeader,
  SecondaryHeaderContainer
} from "../navigation/styles";
import TabMenu, { ITabOption } from "../navigation/TabMenu";
import { useAppState } from "contextApi/appContext";

const Container = styled(SecondaryHeaderContainer)`
  height: 40px;
  margin: 0 9px;
  max-width: 100%;
  padding: 0px;
`;

const AnalyticsMenu: React.FC<{}> = () => {
  const showPostLinksTable = useAppState(state => state.showPostLinksTable);

  const menuOptions: ITabOption[] = useMemo(() => {
    const options = [
      {
        label: "Overview",
        to: "overview"
      },
      {
        label: "Posts",
        to: "posts"
      }
    ];

    if (showPostLinksTable) {
      options.push({
        label: "Links",
        to: "links"
      });
    }

    return options;
  }, [showPostLinksTable]);

  return (
    <SecondaryHeader>
      <Container>
        <TabMenu options={menuOptions} />
      </Container>
    </SecondaryHeader>
  );
};

export default AnalyticsMenu;

import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useToaster } from "@hellocontento/maillard";
import React, { useRef, useEffect, useCallback } from "react";

import {
  Greeting,
  GridItem,
  PaymentGrid,
  CompanyLogos,
  GreetingContainer,
  PaymentDetailsContainer
} from "./styles";
import Breadcrumbs from "../breadcrumbs";
import Button from "components/common/Button";
import { Paragraph } from "components/common/styles";
import { getChargebeeInstance } from "utils/Chargebee";
import { customFormat, fromUnixTime } from "utils/date";
import createEvent, { ICustomEvent } from "utils/events";
import willowLogo from "assets/images/willow-icon-color.png";
import { currencies, chargebeePlansMapping } from "constants/index";
import { trackAnalyticsEvent } from "state/actions/AnalyticsActions";
import { createCheckout, updateQuoteAsPaid } from "services/onboarding";
import defaultCompanyLogo from "assets/images/icons-settings-account.png";
import { IAddonLine } from "containers/quote/QuotePage";

interface IPaymentDetails {
  quoteId: string;
  selectedStep: number;
  addons: IAddonLine[];
  quoteDetails: { [key: string]: any };
  setSelectedStep: (step: number) => void;
  setQuoteDetails: (quoteDetails: { [key: string]: any }) => void;
}

const PaymentDetails: React.FC<IPaymentDetails> = ({
  quoteId,
  quoteDetails,
  selectedStep,
  setSelectedStep,
  setQuoteDetails,
  addons
}) => {
  const history = useHistory();
  const addToast = useToaster();
  const dispatch = useDispatch();
  const cbInstance: any = getChargebeeInstance();
  const {
    plans,
    dbQuote,
    coachings,
    concerned,
    accountManager,
    chargebeeQuote
  } = quoteDetails;
  const { message } = dbQuote;
  const {
    sub_total: subTotal,
    line_items: lineItems,
    valid_till: validTill,
    currency_code: currencyCode,
    total_payable: totalPayable,
    billing_address: billingAddress
  } = chargebeeQuote;
  const { period } = chargebeePlansMapping[plans?.[0] ?? ""] ?? {};

  const onChargebeeCloseEvent = useRef<ICustomEvent>(
    createEvent("@@quoteSystem/onChargebeeCloseEvent")
  );

  const openChargebeePortal = useCallback(async () => {
    try {
      cbInstance.openCheckout({
        hostedPage: () => createCheckout(quoteId),
        error: error => {
          setSelectedStep(1);
          console.error(error);
        },
        loaded: () => {
          dispatch(
            trackAnalyticsEvent("Quote Started", {
              packageType:
                coachings?.length ?? 0 > 0 ? "Coaching" : "Tool Only",
              packageLength:
                period === "monthly"
                  ? "1 month"
                  : period === "biyearly"
                    ? "6 months"
                    : period === "yearly"
                      ? "12 months"
                      : period,
              accountName: billingAddress?.company
            })
          );
        },
        success: async () => {
          try {
            const updatedQuote = await updateQuoteAsPaid(quoteId);
            await setQuoteDetails(prevState => ({
              ...prevState,
              ...updatedQuote
            }));
            await cbInstance.closeAll();
            dispatch(
              trackAnalyticsEvent("Quote Paid", {
                packageType:
                  coachings?.length ?? 0 > 0 ? "Coaching" : "Tool Only",
                packageLength:
                  period === "monthly"
                    ? "1 month"
                    : period === "biyearly"
                      ? "6 months"
                      : period === "yearly"
                        ? "12 months"
                        : period,
                accountName: billingAddress?.company,
                quoteDetails: !!message ? "Yes" : "No"
                // TODO: add paymentType: "Card" | "Direct Debit"
              })
            );
            setSelectedStep(3);
          } catch (err) {
            console.error(err);
            addToast("There was an error with the payment update.", "error");
            history.replace("/");
          }
        },
        close: () => {
          onChargebeeCloseEvent.current.dispatch();
        }
      });
    } catch (_) {
      addToast("There was an error with the payment portal.", "error");
    }
  }, [
    addToast,
    cbInstance,
    dispatch,
    quoteId,
    setSelectedStep,
    history,
    setQuoteDetails
  ]);

  const onChargebeeClose = useCallback(() => {
    if (quoteDetails.dbQuote.status === "paid") {
      setSelectedStep(3);
    } else {
      setSelectedStep(1);
    }
  }, [quoteDetails, setSelectedStep]);

  useEffect(
    () => onChargebeeCloseEvent.current.listen(onChargebeeClose),
    [onChargebeeCloseEvent, onChargebeeClose]
  );

  const handleContinuePayment = useCallback(() => {
    setSelectedStep(2);

    openChargebeePortal();
  }, [setSelectedStep, openChargebeePortal]);

  const currencySymbol = currencies[currencyCode] ?? currencies["EUR"];

  const tax = chargebeeQuote?.taxes?.[0];

  const discount = chargebeeQuote?.discounts?.[0];

  return (
    <PaymentDetailsContainer>
      <CompanyLogos>
        <span>
          <img src={willowLogo} alt="Willow Logo" />
        </span>
        <span className="overlap">
          {
            <img
              src={!!concerned?.logo ? concerned.logo : defaultCompanyLogo}
              alt={`${billingAddress?.company ?? "Customer"} Logo`}
            />
          }
        </span>
      </CompanyLogos>
      {!!message && (
        <GreetingContainer>
          <Greeting>
            <Paragraph mb={0} variant="lightest">
              {message}
            </Paragraph>
            <Paragraph mb={0} variant="lightest">
              <b>{accountManager?.fullName ?? "Brent Schepers"}</b> Account
              Executive
            </Paragraph>
          </Greeting>
        </GreetingContainer>
      )}
      <PaymentGrid>
        {lineItems
          .filter(l => !addons.filter(a => a.id === l.id).length)
          .map(item => {
            const { type, planName, period } =
              chargebeePlansMapping[item.entity_id] ?? {};
            const subscriptionPeriod = (() => {
              if (period === "yearly") {
                return "annual";
              } else if (period === "biyearly") {
                return "bi-yearly";
              } else if (period === "monthly") {
                return "monthly";
              }
            })();

            return (
              // @ts-ignore
              <React.Fragment key={item.id}>
                <GridItem>
                  <p>
                    {type === "coaching" ? "Willow Coaching Package" : planName}
                  </p>
                  <p className="subtitle">{subscriptionPeriod} Subscription</p>
                </GridItem>
                <GridItem>
                  <p>
                    {currencySymbol}
                    {(parseFloat(item.amount) / 100).toFixed(2)}
                  </p>
                </GridItem>
              </React.Fragment>
            );
          })}
        {addons.map(
          (
            item // @ts-ignore
          ) => (
            <React.Fragment key={item.id}>
              <GridItem>
                <p>{item.description}</p>
                <p className="subtitle">Addon</p>
              </GridItem>
              <GridItem>
                <p>
                  {currencySymbol}
                  {(parseFloat(item.amount) / 100).toFixed(2)}
                </p>
              </GridItem>
            </React.Fragment>
          )
        )}

        <GridItem>
          <p className="subtitle">Subtotal</p>
        </GridItem>
        <GridItem>
          <p className="subtitle">
            {currencySymbol}
            {(parseFloat(subTotal) / 100).toFixed(2)}
          </p>
        </GridItem>
        {!!tax && (
          <>
            <GridItem>
              <p className="subtitle">
                {tax.description} @ {tax.tax_percentage}%
              </p>
            </GridItem>
            <GridItem>
              <p className="subtitle">
                {currencySymbol}
                {(parseFloat(tax.amount) / 100).toFixed(2)}
              </p>
            </GridItem>
          </>
        )}
        {!!discount && (
          <>
            <GridItem>
              <p>
                {discount.description} @ {discount.discount_percentage}%
              </p>
            </GridItem>
            <GridItem>
              <p>
                {currencySymbol}
                {(parseFloat(discount.amount) / 100).toFixed(2)}
              </p>
            </GridItem>
          </>
        )}
        <GridItem>
          <p>Total to Pay</p>
        </GridItem>
        <GridItem>
          <p>
            {currencySymbol}
            {(parseFloat(totalPayable) / 100).toFixed(2)}
          </p>
        </GridItem>
      </PaymentGrid>
      {/* @ts-ignore */}
      <Button onClick={handleContinuePayment}>Continue to Payment</Button>
      <span className="quote_deadline">
        This quote expires on{" "}
        {customFormat(fromUnixTime(validTill), "MM/dd/yyyy")}
      </span>
      <Breadcrumbs selectedStep={selectedStep} />
    </PaymentDetailsContainer>
  );
};

export default PaymentDetails;

import { Dispatch } from "react";

import store from "state/store";

import {
  OPEN_COMPOSER,
  CLOSE_COMPOSER,
  TOGGLE_PRO_MODE,
  SET_IN_DRAFT_MODE,
  SET_POST_DATA,
  RESET_POST_DATA,
  ENABLE_SPLIT_MODE,
  DISABLE_SPLIT_MODE,
  SET_TAGS,
  SET_EDITOR_STATE_OBJ,
  SET_VISIBLE_CAPTION,
  SET_VALIDATION_ERRORS,
  SET_CHOSEN_SUGGESTED_CAPTION,
  SET_POST_CONCEPTS,
  SET_POST_IDEA_CAPTIONS,
  SET_ATTACHMENT_TYPE,
  SET_IS_UPLOADING_ATTACHMENTS,
  ADD_ATTACHMENTS,
  REMOVE_ATTACHMENT,
  REMOVE_ARTICLE_ATTACHMENT,
  REORDER_ATTACHMENT,
  SET_ATTACHMENT_VALIDATION_ERROR,
  SET_ATTACHMENTS,
  SET_ATTACHMENT_OPTIONS,
  SET_IS_SUBMITTING,
  SET_IS_DELETING,
  SET_WAS_SCHEDULE_CHANGED,
  SET_PICKED_DATE,
  SET_MOOD_FILTER,
  SET_IS_LOADING_SUGGESTED_IMAGES,
  SET_NEW_IMAGES_INDICATION,
  SET_IMAGES_SEARCH_TERM,
  SET_SUGGESTED_IMAGES,
  SET_IMAGES_QUERY,
  SET_ACTIVE_TOOLKIT,
  SET_CONFIRMATION_MODAL_PROPS,
  SET_IS_COMPOSER_CLOSE_CONFIRMATION_OPEN
} from "./actions";
import { IActions } from "./actionCreators";
import * as storageService from "utils/storage";
import createEvent, { ICustomEvent } from "utils/events";
import { KEY_COMPOSER_PRO_MODE } from "./useComposerContext";
import { trackAnalyticsEvent } from "state/actions/AnalyticsActions";

export interface IMoodFilter {
  label: string;
  value: string;
}

export const MOOD_FILTER_OPTIONS: IMoodFilter[] = [
  {
    label: "Neutral",
    value: "neutral"
  },
  {
    label: "Happy",
    value: "happy"
  },
  {
    label: "Sad",
    value: "sad"
  },
  {
    label: "Funny",
    value: "funny"
  },
  {
    label: "Clever",
    value: "clever"
  },
  {
    label: "Inspiring",
    value: "inspiring"
  },
  {
    label: "Motivational",
    value: "motivational"
  },
  {
    label: "Thoughtful",
    value: "thoughtful"
  },
  {
    label: "Proud",
    value: "proud"
  }
];

// TODO: Turn these into a proper defined interface
export interface IPostData {
  [key: string]: any;
}

export interface ITag {
  [key: string]: any;
}

export interface IValidationError {
  [key: string]: {
    [key: string]: any;
  };
}

export interface ICaption {
  id: string;
  title: string;
  caption: string;
}

export interface IPostIdea {
  id: string;
  title: string;
  subtitle: string;
  captions: ICaption[];
}

export interface IPostConcept {
  id: string;
  captions: any[];
  primaryColor: string;
  secondaryColor: string;
  shortDescription: string;
  slug: string;
  title: string;
  postIdeas: IPostIdea[];
}

export type AttachmentType = "photo" | "video" | "pdf" | "article";

export interface IUploadedAttachment {
  url: string;
  metaData: { [key: string]: any };
}

export interface IScrappedAttachment {
  [key: string]: any;
}

export interface IUploadedAttachments {
  isUploading: boolean;
  attachments: IUploadedAttachment[];
}

export interface IScrappedAttachments {
  isScrapping: boolean;
  attachment: IScrappedAttachment | null;
}

export interface IChannelAttachments {
  validationErrors: any[];
  type: AttachmentType | null;
  options: { [key: string]: any };
  photoAttachments: IUploadedAttachments;
  videoAttachments: IUploadedAttachments;
  pdfAttachments: IUploadedAttachments;
  articleAttachments: IScrappedAttachments;
}

export interface IAttachments {
  [key: string]: IChannelAttachments;
}

export interface IScheduler {
  isSubmitting: boolean;
  isDeleting: boolean;
  wasScheduleChanged: boolean;
  pickedDate: null | Date;
}

export type ToolkitTools = "captionSuggestion" | "imageSuggestion";

export interface IConfirmationModalProps {
  title: string;
  type?: string;
  description: string;
  showOptions: boolean;
  buttonProps: {
    variant: string;
    label: string;
    action: Function;
  };
}

export interface IComposerState {
  isComposerOpen: boolean;
  inProMode: boolean;
  inDraftMode: boolean;
  postData: IPostData;
  inSplitMode: boolean;
  tags: ITag[];
  editorStateObj: any;
  visibleCaption: string;
  validationErrors: IValidationError[];
  chosenSuggestedCaption: { [key: string]: string };
  postConcepts: null | IPostConcept[];
  postIdea: IPostIdea | null;
  attachments: IAttachments;
  scheduler: IScheduler;
  moodFilter: IMoodFilter;
  suggestedImages: {
    isLoading: boolean;
    newImagesIndication: boolean;
    searchTerm: string;
    images: any[];
    query: string;
  };
  activeToolkit: ToolkitTools;
  events: {
    onPostedEvent: ICustomEvent;
    onReloadEvent: ICustomEvent;
    onDeletedEvent: ICustomEvent;
  };
  confirmationModalProps: null | IConfirmationModalProps;
  isComposerCloseConfirmationOpen: boolean;
  closeToRefreshChannels: boolean;
}

export type IComposerDispatch = Dispatch<IActions>;

const uploadedAttachmentsInitialValue: IUploadedAttachments = {
  attachments: [],
  isUploading: false
};

const scrappedAttachmentsInitialValue: IScrappedAttachments = {
  attachment: null,
  isScrapping: false
};

export const initialValues: IComposerState = {
  isComposerOpen: false,
  inProMode: false,
  inDraftMode: false,
  postData: {},
  inSplitMode: false,
  tags: [],
  editorStateObj: {},
  visibleCaption: "all",
  validationErrors: [],
  chosenSuggestedCaption: {},
  postConcepts: null,
  postIdea: null,
  attachments: {
    all: {
      type: null,
      options: {},
      validationErrors: [],
      photoAttachments: { ...uploadedAttachmentsInitialValue },
      videoAttachments: { ...uploadedAttachmentsInitialValue },
      pdfAttachments: { ...uploadedAttachmentsInitialValue },
      articleAttachments: { ...scrappedAttachmentsInitialValue }
    }
  },
  scheduler: {
    isSubmitting: false,
    isDeleting: false,
    wasScheduleChanged: false,
    pickedDate: null
  },
  moodFilter: MOOD_FILTER_OPTIONS[0],
  suggestedImages: {
    isLoading: false,
    newImagesIndication: false,
    searchTerm: "",
    images: [],
    query: ""
  },
  activeToolkit: "captionSuggestion",
  events: {
    onPostedEvent: createEvent("@@composerContext/onPostedEvent"),
    onReloadEvent: createEvent("@@composerContext/onReloadEvent"),
    onDeletedEvent: createEvent("@@composerContext/onDeletedEvent")
  },
  confirmationModalProps: null,
  isComposerCloseConfirmationOpen: false,
  closeToRefreshChannels: false
};

export const composerReducer = (
  state: IComposerState = initialValues,
  action: IActions
): IComposerState => {
  switch (action.type) {
    case OPEN_COMPOSER:
      return {
        ...state,
        isComposerOpen: true
      };

    case CLOSE_COMPOSER:
      return {
        ...state,
        isComposerOpen: false
      };

    case TOGGLE_PRO_MODE: {
      const enableProMode = action.payload ?? !state.inProMode;

      if (enableProMode) {
        store.dispatch(trackAnalyticsEvent("Pro Mode Enabled"));
      } else {
        store.dispatch(trackAnalyticsEvent("Pro Mode Disabled"));
      }

      storageService.set(
        KEY_COMPOSER_PRO_MODE,
        enableProMode ? "true" : "false"
      );

      return {
        ...state,
        inProMode: enableProMode
      };
    }

    case SET_IN_DRAFT_MODE:
      return {
        ...state,
        inDraftMode: action.payload
      };

    case SET_POST_DATA: {
      return {
        ...state,
        postData: {
          ...state.postData,
          ...action.payload
        }
      };
    }

    case RESET_POST_DATA: {
      return {
        ...initialValues
      };
    }

    case ENABLE_SPLIT_MODE:
      return {
        ...state,
        inSplitMode: true
      };

    case DISABLE_SPLIT_MODE:
      return {
        ...state,
        inSplitMode: false
      };

    case SET_TAGS: {
      return {
        ...state,
        tags: action.payload
      };
    }

    case SET_EDITOR_STATE_OBJ: {
      return {
        ...state,
        editorStateObj: action.payload
      };
    }

    case SET_VISIBLE_CAPTION: {
      return {
        ...state,
        visibleCaption: action.payload,
        // ? initiate attachment object for the channel
        // ? (only applicable when there is a need for different attachments for different channels)
        attachments: {
          ...state.attachments,
          [action.payload]: {
            ...(state.attachments[action.payload] ||
              initialValues.attachments["all"])
          }
        }
      };
    }

    case SET_VALIDATION_ERRORS: {
      return {
        ...state,
        validationErrors: {
          ...state.validationErrors,
          ...action.payload
        }
      };
    }

    case SET_CHOSEN_SUGGESTED_CAPTION: {
      return {
        ...state,
        chosenSuggestedCaption: {
          ...state.chosenSuggestedCaption,
          ...action.payload
        }
      };
    }

    case SET_POST_CONCEPTS: {
      return {
        ...state,
        postConcepts: action.payload
      };
    }

    case SET_POST_IDEA_CAPTIONS: {
      const postConcepts = state.postConcepts ?? [];

      const { postConceptIndex, postIdeaIndex } = postConcepts.reduce(
        (acc, postConcept, postConceptIndex) => {
          const postIdeaIndex = postConcept.postIdeas.findIndex(
            postIdea => postIdea.id === action.postIdeaId
          );

          if (postIdeaIndex > -1) {
            acc.postConceptIndex = postConceptIndex;
            acc.postIdeaIndex = postIdeaIndex;
          }
          return acc;
        },
        { postConceptIndex: -1, postIdeaIndex: -1 }
      );

      if (postConceptIndex === -1 || postIdeaIndex === -1) {
        return state;
      }

      const updatedPostConcept = {
        ...postConcepts[postConceptIndex],
        postIdeas: [
          ...postConcepts[postConceptIndex].postIdeas.slice(0, postIdeaIndex),
          {
            ...postConcepts[postConceptIndex].postIdeas[postIdeaIndex],
            captions: action.payload
          },
          ...postConcepts[postConceptIndex].postIdeas.slice(postIdeaIndex + 1)
        ]
      };

      return {
        ...state,
        postConcepts: [
          ...postConcepts.slice(0, postConceptIndex),
          updatedPostConcept,
          ...postConcepts.slice(postConceptIndex + 1)
        ]
      };
    }

    case SET_ATTACHMENT_TYPE: {
      return {
        ...state,
        attachments: {
          ...state.attachments,
          [action.channel]: {
            ...(state.attachments[action.channel] ||
              initialValues.attachments["all"]),
            type: action.payload
          }
        }
      };
    }

    case SET_IS_UPLOADING_ATTACHMENTS: {
      let newValue = {
        ...(state.attachments[action.channel] ||
          initialValues.attachments["all"]),
        type:
          action.attachmentType === "article"
            ? state.attachments[action.channel].type === null
              ? action.attachmentType
              : state.attachments[action.channel].type
            : action.attachmentType
      } as IChannelAttachments;
      if (action.attachmentType === "photo") {
        newValue = {
          ...newValue,
          photoAttachments: {
            ...(state.attachments[action.channel].photoAttachments ||
              uploadedAttachmentsInitialValue),
            isUploading: action.payload
          }
        };
      } else if (action.attachmentType === "video") {
        newValue = {
          ...newValue,
          videoAttachments: {
            ...(state.attachments[action.channel].videoAttachments ||
              uploadedAttachmentsInitialValue),
            isUploading: action.payload
          }
        };
      } else if (action.attachmentType === "pdf") {
        newValue = {
          ...newValue,
          pdfAttachments: {
            ...(state.attachments[action.channel].pdfAttachments ||
              uploadedAttachmentsInitialValue),
            isUploading: action.payload
          }
        };
      } else {
        newValue = {
          ...newValue,
          articleAttachments: {
            ...(state.attachments[action.channel].articleAttachments ||
              scrappedAttachmentsInitialValue),
            isScrapping: action.payload
          }
        };
      }

      return {
        ...state,
        attachments: {
          ...state.attachments,
          [action.channel]: newValue
        }
      };
    }

    case ADD_ATTACHMENTS: {
      let newValue = {
        ...(state.attachments[action.channel] ||
          initialValues.attachments["all"])
      } as IChannelAttachments;
      if (action.attachmentType === "photo") {
        newValue = {
          ...newValue,
          photoAttachments: {
            ...(state.attachments[action.channel].photoAttachments ||
              uploadedAttachmentsInitialValue),
            attachments: [
              ...newValue.photoAttachments.attachments,
              ...action.payload
            ]
          }
        };
      } else if (action.attachmentType === "video") {
        newValue = {
          ...newValue,
          videoAttachments: {
            ...(state.attachments[action.channel].videoAttachments ||
              uploadedAttachmentsInitialValue),
            attachments: [...action.payload] // ? replacing the video
          }
        };
      } else if (action.attachmentType === "pdf") {
        newValue = {
          ...newValue,
          pdfAttachments: {
            ...(state.attachments[action.channel].pdfAttachments ||
              uploadedAttachmentsInitialValue),
            attachments: [...action.payload] // ? replacing the pdf
          }
        };
      } else {
        newValue = {
          ...newValue,
          articleAttachments: {
            ...(state.attachments[action.channel].articleAttachments ||
              scrappedAttachmentsInitialValue),
            attachment: {
              ...action.payload
            }
          }
        };
      }

      return {
        ...state,
        attachments: {
          ...state.attachments,
          [action.channel]: newValue
        }
      };
    }

    case REMOVE_ATTACHMENT: {
      let newValue = {
        ...(state.attachments[action.channel] ||
          initialValues.attachments["all"])
      } as IChannelAttachments;
      if (action.attachmentType === "photo") {
        newValue = {
          ...newValue,
          photoAttachments: {
            ...(state.attachments[action.channel].photoAttachments ||
              uploadedAttachmentsInitialValue),
            attachments: [
              ...newValue.photoAttachments.attachments.slice(0, action.index),
              ...newValue.photoAttachments.attachments.slice(action.index + 1)
            ]
          }
        };
      } else if (action.attachmentType === "video") {
        newValue = {
          ...newValue,
          videoAttachments: {
            ...(state.attachments[action.channel].videoAttachments ||
              uploadedAttachmentsInitialValue),
            attachments: [
              ...newValue.videoAttachments.attachments.slice(0, action.index),
              ...newValue.videoAttachments.attachments.slice(action.index + 1)
            ]
          }
        };
      } else if (action.attachmentType === "pdf") {
        newValue = {
          ...newValue,
          pdfAttachments: {
            ...(state.attachments[action.channel].pdfAttachments ||
              uploadedAttachmentsInitialValue),
            attachments: [
              ...newValue.pdfAttachments.attachments.slice(0, action.index),
              ...newValue.pdfAttachments.attachments.slice(action.index + 1)
            ]
          }
        };
      }

      return {
        ...state,
        attachments: {
          ...state.attachments,
          [action.channel]: newValue
        }
      };
    }

    case REMOVE_ARTICLE_ATTACHMENT: {
      const newValue = {
        ...(state.attachments[action.channel] ||
          initialValues.attachments["all"]),
        type: null,
        articleAttachments: {
          ...(state.attachments[action.channel].articleAttachments ||
            scrappedAttachmentsInitialValue),
          attachment: null
        }
      };

      return {
        ...state,
        attachments: {
          ...state.attachments,
          [action.channel]: newValue
        }
      };
    }

    case REORDER_ATTACHMENT: {
      let newValue = {
        ...(state.attachments[action.channel] ||
          initialValues.attachments["all"])
      } as IChannelAttachments;
      if (action.attachmentType === "photo") {
        const attachments = [...newValue.photoAttachments.attachments];
        const [selectedItem] = attachments.splice(action.sourceIndex, 1);
        attachments.splice(action.destinationIndex, 0, selectedItem);

        newValue = {
          ...newValue,
          photoAttachments: {
            ...(state.attachments[action.channel].photoAttachments ||
              uploadedAttachmentsInitialValue),
            attachments
          }
        };
      } else if (action.attachmentType === "video") {
        const attachments = [...newValue.videoAttachments.attachments];
        const [selectedItem] = attachments.splice(action.sourceIndex, 1);
        attachments.splice(action.destinationIndex, 0, selectedItem);

        newValue = {
          ...newValue,
          videoAttachments: {
            ...(state.attachments[action.channel].videoAttachments ||
              uploadedAttachmentsInitialValue),
            attachments
          }
        };
      } else if (action.attachmentType === "pdf") {
        const attachments = [...newValue.pdfAttachments.attachments];
        const [selectedItem] = attachments.splice(action.sourceIndex, 1);
        attachments.splice(action.destinationIndex, 0, selectedItem);

        newValue = {
          ...newValue,
          pdfAttachments: {
            ...(state.attachments[action.channel].pdfAttachments ||
              uploadedAttachmentsInitialValue),
            attachments
          }
        };
      }

      return {
        ...state,
        attachments: {
          ...state.attachments,
          [action.channel]: newValue
        }
      };
    }

    case SET_ATTACHMENT_VALIDATION_ERROR: {
      return {
        ...state,
        attachments: {
          ...state.attachments,
          [action.channel]: {
            ...(state.attachments[action.channel] ||
              initialValues.attachments["all"]),
            validationErrors: action.payload
          }
        }
      };
    }

    case SET_ATTACHMENTS: {
      return {
        ...state,
        attachments: {
          ...state.attachments,
          [action.channel]: {
            ...(state.attachments[action.channel] ||
              initialValues.attachments["all"]),
            ...action.payload
          }
        }
      };
    }

    case SET_ATTACHMENT_OPTIONS: {
      return {
        ...state,
        attachments: {
          ...state.attachments,
          [action.channel]: {
            ...(state.attachments[action.channel] ||
              initialValues.attachments["all"]),
            options: {
              ...(
                state.attachments[action.channel] ||
                initialValues.attachments["all"]
              ).options,
              ...action.payload
            }
          }
        }
      };
    }

    case SET_IS_SUBMITTING: {
      return {
        ...state,
        scheduler: {
          ...state.scheduler,
          isSubmitting: action.payload
        }
      };
    }

    case SET_IS_DELETING: {
      return {
        ...state,
        scheduler: {
          ...state.scheduler,
          isDeleting: action.payload
        }
      };
    }

    case SET_WAS_SCHEDULE_CHANGED: {
      return {
        ...state,
        scheduler: {
          ...state.scheduler,
          wasScheduleChanged: action.payload
        }
      };
    }

    case SET_PICKED_DATE: {
      return {
        ...state,
        scheduler: {
          ...state.scheduler,
          pickedDate: action.payload
        }
      };
    }

    case SET_MOOD_FILTER: {
      return {
        ...state,
        moodFilter: action.payload
      };
    }

    case SET_IS_LOADING_SUGGESTED_IMAGES: {
      return {
        ...state,
        suggestedImages: {
          ...state.suggestedImages,
          isLoading: action.payload
        }
      };
    }

    case SET_NEW_IMAGES_INDICATION: {
      return {
        ...state,
        suggestedImages: {
          ...state.suggestedImages,
          newImagesIndication: action.payload
        }
      };
    }

    case SET_IMAGES_SEARCH_TERM: {
      return {
        ...state,
        suggestedImages: {
          ...state.suggestedImages,
          searchTerm: action.payload
        }
      };
    }

    case SET_SUGGESTED_IMAGES: {
      return {
        ...state,
        suggestedImages: {
          ...state.suggestedImages,
          images:
            action.payload.length <= 0
              ? []
              : [...state.suggestedImages.images, ...action.payload]
        }
      };
    }

    case SET_IMAGES_QUERY: {
      return {
        ...state,
        suggestedImages: {
          ...state.suggestedImages,
          query: action.payload
        }
      };
    }

    case SET_ACTIVE_TOOLKIT: {
      return {
        ...state,
        activeToolkit: action.payload
      };
    }

    case SET_CONFIRMATION_MODAL_PROPS: {
      return {
        ...state,
        confirmationModalProps: action.payload
      };
    }

    case SET_IS_COMPOSER_CLOSE_CONFIRMATION_OPEN: {
      return {
        ...state,
        isComposerCloseConfirmationOpen:
          action.payload.isComposerCloseConfirmationOpen,
        closeToRefreshChannels:
          typeof action.payload.closeToRefreshChannels === "boolean"
            ? action.payload.closeToRefreshChannels
            : false
      };
    }

    default: {
      throw new Error("Unhandled action type");
    }
  }
};

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useGranularCallback, useGranularEffect } from "granular-hooks";

import {
  LeftPanel,
  RightPanel,
  CenterPanel,
  ComposerBody,
  ComposerFooter
} from "./styles";
import {
  useComposerState,
  useComposerActions
} from "contextApi/composerContext";
import Toolkit from "./components/toolkit";
import { formatForScheduler } from "utils/date";
import Properties from "./components/properties";
import { openModal } from "state/actions/ModalActions";
import Scheduler from "./components/postComposer/scheduler";
import { OnboardingStepsEnum } from "types/Onboarding.types";
import Attachment from "./components/postComposer/attachment";
import ComposerModal from "components/modals/composerV2Modal";
import useAlertModal from "components/modals/pop-ups/useAlertModal";
import { TimeDisplay } from "components/modals/composerV2Modal/styles";
import PostComposer from "components/composerv2/components/postComposer";
import ConfirmationModal from "components/schedule/form/TaskConfirmationModal";
import AttachmentTools from "./components/postComposer/attachment/AttachmentToolbar";

import willowAiImage from "assets/images/composer/feature-introduction/willow-ai.jpg";
import categoriesImage from "assets/images/composer/feature-introduction/categories.jpg";
import introBannerImage from "assets/images/composer/feature-introduction/intro-banner.png";
import imageSuggestionImage from "assets/images/composer/feature-introduction/image-suggestion.jpg";
import captionSuggestionImage from "assets/images/composer/feature-introduction/caption-suggestion.jpg";
//import CanvaSuggestion from "./components/toolkit/CanvaSuggestion";

const Composer = () => {
  const postDataId = useComposerState(state => state.postData.id);
  const inDraftMode = useComposerState(state => state.inDraftMode);
  const isComposerOpen = useComposerState(state => state.isComposerOpen);
  const confirmationModalProps = useComposerState(
    state => state.confirmationModalProps
  );
  const inProMode = useComposerState(state => state.inProMode);
  const onPostedEvent = useComposerState(state => state.events.onPostedEvent);
  const toggleProMode = useComposerActions(actions => actions.toggleProMode);
  const setConfirmationModalProps = useComposerActions(
    actions => actions.setConfirmationModalProps
  );
  const setIsComposerCloseConfirmationOpen = useComposerActions(
    actions => actions.setIsComposerCloseConfirmationOpen
  );
  const account = useSelector(state => state.account.data);
  const reduxDispatch = useDispatch();
  const addFeatureAlert = useAlertModal();

  const useComposerV2 = !account?.isTelenetCustomer;

  useGranularEffect(
    () => {
      if (isComposerOpen && useComposerV2) {
        const newFeature = {
          id: "composer-v2-introduction",
          steps: [
            {
              title: "Welcome to our new composer",
              description:
                "We have updated our composer with some new and exciting features. Although it may look a little different than before, we hope you'll enjoy using it!",
              banner: introBannerImage
            },
            {
              title: "Select category",
              description:
                "When you make a post, you can choose a category that best fits what you're talking about. Depending on the category you choose, Willow AI will give you different suggestions to help you with your post.",
              banner: categoriesImage
            },
            {
              title: "Caption generation sidebar",
              description:
                "You can type in words or phrases, and Willow AI will come up with different options for you to use as captions. It's an easy way to save time and get inspiration for your social media posts!",
              banner: captionSuggestionImage
            },
            {
              title: "Introducing Caption Autocomplete",
              description:
                "The autocomplete area is a tool that suggests words or phrases to complete your caption. It helps you create a better caption with less effort.",
              banner: willowAiImage
            },
            {
              title: "Image sidebar",
              description:
                "When you're working on a post and need to add pictures, you can find them easily by using the app's image search sidebar.",
              banner: imageSuggestionImage,
              proceedButtonText: "Finish"
            }
          ]
        };

        addFeatureAlert(newFeature);
      }
    },
    [isComposerOpen, useComposerV2],
    [addFeatureAlert]
  );

  const accountOnboardingProgress = account?.onboardingProgress;

  const handlePosted = useGranularCallback(
    res => {
      const post = (res.posts ? res.posts[0] : undefined) || res.post || res;

      try {
        if (!res.isDraft && !!accountOnboardingProgress) {
          reduxDispatch(
            openModal("ONBOARDING_INFO_MODAL", {
              id: OnboardingStepsEnum.CREATE_POST,
              variant: "success",
              title: res.isPostedNow
                ? "Your post has been published."
                : "Your post has been scheduled.",
              subTitle: !res.isPostedNow ? (
                <>
                  Your post has been scheduled for <br />
                  <TimeDisplay>
                    {formatForScheduler(
                      new Date(post.scheduledAt.split(".")[0])
                    )}
                  </TimeDisplay>
                </>
              ) : (
                "Your post has been sent and will appear on your social media."
              ),
              forOnboarding: !accountOnboardingProgress.includes(
                OnboardingStepsEnum.CREATE_POST
              ),
              ...(!accountOnboardingProgress.includes(
                OnboardingStepsEnum.CREATE_POST
              )
                ? { triggeredBy: OnboardingStepsEnum.CREATE_POST }
                : { secondaryAction: false })
            })
          );
        }
      } catch (_) {}
    },
    [accountOnboardingProgress],
    [reduxDispatch]
  );

  useEffect(() => onPostedEvent.listen(handlePosted), [
    onPostedEvent,
    handlePosted
  ]);

  const title = `${postDataId ? "Edit" : "New"} ${
    inDraftMode ? "Draft" : "Post"
  }`;

  return (
    <>
      <ConfirmationModal
        isOpen={!!confirmationModalProps}
        {...(confirmationModalProps ?? {})}
        toggle={() => setConfirmationModalProps(null)}
      />
      <ComposerModal
        title={title}
        proMode={inProMode}
        isOpen={isComposerOpen}
        toggleProMode={useComposerV2 ? toggleProMode : null}
        onClose={() => setIsComposerCloseConfirmationOpen(true)}
      >
        {isComposerOpen && (
          <ComposerBody>
            {inProMode && (
              <LeftPanel>
                <Properties />
                <Scheduler />
              </LeftPanel>
            )}
            <CenterPanel inProMode={inProMode}>
              <PostComposer />
              {inProMode && <Attachment />}
              {!inProMode && (
                <ComposerFooter>
                  <AttachmentTools showBorder={true} />
                  <Scheduler />
                </ComposerFooter>
              )}
            </CenterPanel>
            {inProMode && (
              <RightPanel>
                <Toolkit />
              </RightPanel>
            )}
          </ComposerBody>
        )}
      </ComposerModal>
    </>
  );
};

export default Composer;

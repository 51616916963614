import {
  Context,
  createContext,
  useContextSelector
} from "use-context-selector";
import { useFlags, useLDClientError } from "launchdarkly-react-client-sdk";
import React, { useMemo, useEffect, useReducer, useCallback } from "react";

import {
  setWindowSizeAction,
  setClientSettingsAction,
  setIsDraftPanelOpenAction
} from "./actionCreators";
import useWindowSize from "utils/useWindowSize";
import { clients } from "constants/clientSettings";
import { IAppState, appReducer, initialValues, WindowSize } from "./reducer";

export interface IFeatureFlags {
  ldError: Error | undefined;
  showWillowExtension: boolean | null;
  showReferralPage: boolean | null;
  showPdfAttachment: boolean | null;
  showTabularAnalytics: boolean | null;
  showPostLinksTable: boolean | null;
}

const AppStateContext = createContext<(IAppState & IFeatureFlags) | undefined>(
  undefined
);
AppStateContext.displayName = "AppStateContext";

export interface IAppActions {
  setIsDraftPanelOpen: (state: boolean) => void;
}

const AppActionContext = createContext<IAppActions | undefined>(undefined);
AppActionContext.displayName = "AppActionContext";

export const AppProvider: React.FC<any> = React.memo(({ children }) => {
  const currentWindowSize = useWindowSize();
  const [state, dispatch] = useReducer(appReducer, {
    ...initialValues
  });
  const { windowSize } = state;
  const {
    willowExtension,
    referralPage,
    pdfAttachment,
    tabularAnalytics,
    postLinksTable
  } = useFlags();
  const ldError = useLDClientError();

  useEffect(() => {
    if (
      !!process.env.REACT_APP_CLIENT &&
      Object.keys(clients).includes(process.env.REACT_APP_CLIENT)
    ) {
      dispatch(setClientSettingsAction(clients[process.env.REACT_APP_CLIENT]));
    }
  }, []);

  useEffect(() => {
    if (!!currentWindowSize?.size && windowSize !== currentWindowSize.size) {
      const size = Object.values(WindowSize).find(
        value => value === currentWindowSize.size
      );
      if (!!size) {
        dispatch(setWindowSizeAction(size));
      }
    }
  }, [currentWindowSize, windowSize]);

  // * STATE MODIFIERS
  const setIsDraftPanelOpen: IAppActions["setIsDraftPanelOpen"] = useCallback(
    state => {
      dispatch(setIsDraftPanelOpenAction(state));
    },
    []
  );

  const values = useMemo(
    () => ({
      ...state,
      ldError,
      showWillowExtension: willowExtension ?? null,
      showReferralPage: referralPage ?? null,
      showPdfAttachment: pdfAttachment ?? null,
      showTabularAnalytics: tabularAnalytics ?? null,
      showPostLinksTable: postLinksTable ?? null
    }),
    [
      state,
      willowExtension,
      referralPage,
      pdfAttachment,
      tabularAnalytics,
      postLinksTable,
      ldError
    ]
  );

  const actions = useMemo(
    () => ({
      setIsDraftPanelOpen
    }),
    [setIsDraftPanelOpen]
  );

  return (
    <AppStateContext.Provider value={values}>
      <AppActionContext.Provider value={actions}>
        {children}
      </AppActionContext.Provider>
    </AppStateContext.Provider>
  );
});

export const useAppState = <T,>(
  selector: (state: IAppState & IFeatureFlags) => T
): T => {
  try {
    return useContextSelector(
      AppStateContext as Context<IAppState & IFeatureFlags>,
      selector
    );
  } catch (_) {
    throw new Error("useAppState must be used within a AppProvider");
  }
};

export const useAppActions = <T,>(selector: (state: IAppActions) => T): T => {
  const context = useContextSelector(
    AppActionContext as Context<IAppActions>,
    selector
  );

  if (context === undefined) {
    throw new Error("useAppActions must be used within a AppProvider");
  }
  return context;
};

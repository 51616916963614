import { connect } from "react-redux";
import React, { CSSProperties } from "react";
import {
  Droppable,
  DraggableRubric,
  DraggableProvided,
  DroppableProvided,
  DroppableStateSnapshot,
  DraggableStateSnapshot
} from "react-beautiful-dnd";
import { IAccount } from "@hellocontento/contento-common";

import CalendarEntry from "components/schedule/calendar/CalendarEntry";

interface IDroppableWrapperProps {
  id: string;
  height: string;
  account: IAccount;
  isDisabled: boolean;
  entries: Array<any>;
  children: JSX.Element;
  applyStyling?: (isDraggingOver: boolean) => CSSProperties;
}

const DroppableWrapper = ({
  id,
  height,
  account,
  children,
  isDisabled,
  entries = [],
  applyStyling
}: IDroppableWrapperProps) => {
  // This method is needed for rendering clones of draggables
  const getRenderItem =
    (entries: Array<any>, height: any) =>
    (
      provided: DraggableProvided,
      snapshot: DraggableStateSnapshot,
      rubric: DraggableRubric
    ) => {
      const entry = entries.find(
        entry =>
          !entry.isPhantom &&
          entry.cardId === rubric.draggableId.split("/").pop()
      );

      if (!entry) return <></>;

      return (
        <React.Fragment>
          <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            style={provided.draggableProps.style}
            className={snapshot.isDragging ? "dragging" : ""}
          >
            <CalendarEntry
              accountId={account.id}
              key={entry.id}
              entry={entry}
              height={height}
              // @ts-ignore
              attachmentHeight={175}
            />
          </div>
        </React.Fragment>
      );
    };

  return (
    <Droppable
      droppableId={id}
      isDropDisabled={isDisabled}
      renderClone={getRenderItem(entries, height)}
    >
      {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          style={applyStyling && applyStyling(snapshot.isDraggingOver)}
        >
          {children}
          <div style={{ height: 0, width: 0 }}>{provided.placeholder}</div>
        </div>
      )}
    </Droppable>
  );
};

const mapStateToProps = (state: any) => {
  return {
    account: state.account.data
  };
};

export default connect(mapStateToProps, {})(DroppableWrapper);

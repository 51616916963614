import React, { useState } from "react";
// @ts-ignore
import { useToaster } from "@hellocontento/maillard";
import {
  ItemWrapper,
  Label,
  SourceWrapper,
  SourceImage,
  SourceType,
  LeftSection,
  SourceImageAlternative,
  SourceKeywordImage
} from "./styles";
import { NavigationItem, ExploreButton } from "../../styles";
import { ToolTip } from "components/schedule/common/styles";

import { ITopic, ISource, unfollow, IDomain, IKeyword } from "services/news";

import TwitterSource from "assets/images/social/twitter-black-circle.png";

import RSSSource from "assets/images/social/rss-black-circle.png";
import GoogleSource from "assets/images/contents/Keyword-Monitor.png";

import TaskConfirmationModal from "components/schedule/form/TaskConfirmationModal";
import { deleteKeyword } from "services/keyword";

interface IPanelItemProps {
  to: string;
  type: string;
  borderStyle: string;
  isCollapsed: boolean;
  item: ITopic | ISource | IDomain | IKeyword;
}

const PanelItem: React.FC<IPanelItemProps> = ({
  to,
  type,
  item,
  borderStyle,
  isCollapsed
}) => {
  const addToast = useToaster();
  const [onHover, setOnHover] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);

  const [isImageLoadError, setImageLoadError] = useState<boolean>(false);

  const toggleConfirmationModal = () =>
    setShowConfirmationModal(!showConfirmationModal);

  const sourceImage = (function () {
    const data = item as ISource;

    switch (data.type) {
      case "twitter":
        return TwitterSource;
      case "rss":
        return RSSSource;
      case "keywords":
        return GoogleSource;
      default:
        return null;
    }
  })();

  const isCurrent = to === window.location.pathname;

  const handleUnfollow = async () => {
    const sectionType = (item as IDomain).domain ? "domains" : type;

    try {
      await unfollow(sectionType, item.id);
    } catch (err) {
      addToast(err, "error");
    }
  };

  const handleRemoveKeyword = async () => {
    try {
      await deleteKeyword(item.id);
    } catch (err) {
      addToast(err, "error");
    }
  };

  const deletionModalProps = {
    title: "Unfollow",
    description: "You're unfollowing a source.",
    type: "series",
    showOptions: false,
    toggle: toggleConfirmationModal,
    buttonProps: {
      variant: "danger",
      label: "Unfollow",
      action:
        (item as IKeyword)?.type === "keywords"
          ? handleRemoveKeyword
          : handleUnfollow
    }
  };

  const title = "rules" in item ? item.name : item.title;
  const image = "rules" in item ? null : item.image;

  return (
    <>
      <TaskConfirmationModal
        isOpen={showConfirmationModal}
        {...deletionModalProps}
      />
      <NavigationItem
        to={to}
        exact={true}
        onMouseEnter={() => setOnHover(true)}
        onMouseLeave={() => setOnHover(false)}
        px={6}
        data-tip
        data-for={title}
      >
        <ItemWrapper>
          <LeftSection>
            <SourceWrapper>
              {isImageLoadError ? (
                <SourceImageAlternative borderStyle={borderStyle} />
              ) : image ? (
                <SourceImage
                  src={image}
                  borderStyle={borderStyle}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    setImageLoadError(true);
                  }}
                />
              ) : (
                <SourceKeywordImage>
                  <span className="icon-search" />
                </SourceKeywordImage>
              )}
              {sourceImage && <SourceType source={sourceImage} />}
            </SourceWrapper>
            {!isCollapsed && <Label className="panel_text">{title}</Label>}
          </LeftSection>
          {onHover && !isCollapsed && (
            <ExploreButton
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                toggleConfirmationModal();
              }}
              hover={onHover}
              isCurrent={isCurrent}
            >
              <span className="icon-delete"></span>
            </ExploreButton>
          )}
        </ItemWrapper>
      </NavigationItem>
      {isCollapsed && (
        <ToolTip id={title} place="right" className="tooltip">
          {title}
        </ToolTip>
      )}
    </>
  );
};

export default PanelItem;

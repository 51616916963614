import axios from "axios";
import React, { useState, useEffect, useRef, useCallback } from "react";

import UploadZone from "./UploadZone";
import Loader from "components/common/loading/Loader";
import { useContentoApi } from "utils/useContentoApi";
import { ImageWrapper, DropZoneContainer } from "./styles";

const CancelToken = axios.CancelToken;

const VideoUpload = ({ onError, onUpload, isUploading, setIsUploading }) => {
  const [progress, setProgress] = useState(0);

  const [getSignedUrl, cancelGetSignedUrl] = useContentoApi(
    "videos/upload-url",
    "post"
  );
  const [uploadTarget, setUploadTarget] = useState(null);
  const [video, setVideo] = useState(null);
  const [videoMetaData, setVideoMetaData] = useState(null);

  const cancelTokenRef = useRef(null);
  const cancelUpload = useCallback(() => {
    if (cancelTokenRef.current !== null) {
      cancelTokenRef.current.cancel();
      cancelTokenRef.current = null;
      console.log("Upload cancelled");
    }
  }, [cancelTokenRef]);

  //Cancel upload on unmount
  useEffect(() => {
    return cancelUpload;
  }, [cancelUpload]);

  const onDrop = videos => {
    if (isUploading) {
      cancelUpload();
    }
    if (videos.length === 0) {
      onError(new Error("Make sure file attached is a video"));
      return;
    }

    const video = videos[0];

    if (video.type !== "video/mp4") {
      onError(new Error("Currently, only mp4 H.264 format is allowed"));
      return;
    }

    if (video.size > 500 * 1024 * 1024) {
      onError(new Error("The video file size cannot exceed 500MB"));
      return;
    }

    let reader = new FileReader();
    reader.onload = e => {
      const videoElement = document.createElement("video");
      videoElement.onerror = () => {
        console.error(videoElement.error);
        onError(
          new Error(
            "We were unable to process the video file: " +
              videoElement.error?.message
          )
        );
      };

      videoElement.onloadedmetadata = () => {
        let duration = Math.floor(videoElement.duration);
        setVideoMetaData({
          duration: duration,
          videoWidth: videoElement.videoWidth,
          videoHeight: videoElement.videoHeight,
          fileSize: video.size,
          fileType: video.type
        });
        setVideo(video);
      };

      // The file reader gives us an ArrayBuffer:
      let buffer = e.target.result;
      // We have to convert the buffer to a blob:
      let videoBlob = new Blob([new Uint8Array(buffer)], { type: "video/mp4" });
      // The blob gives us a URL to the video file:
      let url = window.URL.createObjectURL(videoBlob);
      videoElement.src = url;
      videoElement.setAttribute("src", url);
    };
    reader.readAsArrayBuffer(video);
  };

  useEffect(() => {
    if (video === null) {
      return;
    }
    getSignedUrl({
      params: {
        name: video.name
      }
    }).then(result => setUploadTarget(result));
    return cancelGetSignedUrl;
  }, [video, getSignedUrl, cancelGetSignedUrl]);

  useEffect(() => {
    if (uploadTarget === null || video === null || isUploading === true) {
      return;
    }

    setIsUploading(true);

    cancelTokenRef.current = CancelToken.source();

    axios({
      method: "put",
      url: uploadTarget.url,
      data: video,
      headers: { "Content-Type": "video/mp4" },
      onUploadProgress: function (progressEvent) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(percentCompleted);
      },
      cancelToken: cancelTokenRef.current.token
    })
      .then(response => {
        cancelTokenRef.current = null;
        setIsUploading(false);
        onUpload([{ path: uploadTarget.path, metaData: videoMetaData }]);
      })
      .catch(err => {
        setIsUploading(false);
        cancelTokenRef.current = null;
        if (!axios.isCancel(err)) {
          setUploadTarget(null);
          onError(err);
        } else {
          //Don't show any error if this is a cancellation.
          err = null;
        }
      });
  }, [uploadTarget, video, onUpload, isUploading, cancelUpload, videoMetaData]);

  return (
    <>
      {isUploading ? (
        <DropZoneContainer
          alignItems="center"
          justifyContent="center"
          flexDirection="row"
          height="120px"
          width="120px"
          minHeight="120px"
          type={"video"}
          disabled={true}
        >
          <ImageWrapper progress={0}>
            <Loader
              location="center"
              size={24}
              color="#0063FB"
              progress={progress}
            />
          </ImageWrapper>
        </DropZoneContainer>
      ) : (
        <UploadZone
          accept="video/*"
          isUploading={isUploading}
          onDrop={onDrop}
          type={"video"}
        />
      )}
    </>
  );
};

export default VideoUpload;

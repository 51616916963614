import styled from "styled-components";
import React, { useState } from "react";

import Popper from "components/common/Popper";
import { StyledButton } from "components/common/Button";
import { ChevronDown } from "styled-icons/boxicons-regular";
import Menu, { MenuContainer } from "components/navigation/Menu";
import { Text } from "rebass/styled-components";

const PeriodButton = styled(StyledButton)`
  background: transparent;
  color: ${props => props.theme.colors.text02};
  column-gap: 4px;
  display: grid;
  font-family: ${props => props.theme.fonts.body};
  grid-template-columns: 20px 1fr 20px;
  height: 36px;
  padding: 0 10px;
  width: fit-content;
`;

PeriodButton.defaultProps = {
  variant: "secondary"
};

const Icon = styled.span`
  display: inline-block;
  font-size: 20px;
  text-align: center;
  width: 20px;
`;

export interface IPeriodMenuItem {
  label: string;
  value: 7 | 30 | 90 | 180 | 365;
}

export const periodMenuItems: IPeriodMenuItem[] = [
  {
    label: "7 days",
    value: 7
  },
  {
    label: "1 month",
    value: 30
  },
  {
    label: "3 months",
    value: 90
  },
  {
    label: "6 months",
    value: 180
  },
  {
    label: "1 year",
    value: 365
  }
];

interface IPeriodSelect {
  selected?: IPeriodMenuItem["value"];
  onSelect: (value: IPeriodMenuItem["value"]) => void;
}

const PeriodSelect: React.FC<IPeriodSelect> = ({
  selected = periodMenuItems[0].value,
  onSelect
}) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const toggleToolTipVisibility = () => setTooltipVisible(!tooltipVisible);

  const [referenceElement, setReferenceElement] = useState(null);

  const selectedObj = periodMenuItems.find(v => v.value === selected);

  return (
    <PeriodButton
      ref={setReferenceElement}
      size="sm"
      onClick={toggleToolTipVisibility}
      variant="secondary"
    >
      <Icon className="icon-calendar" /> <Text fontWeight={400}>{selectedObj?.label}</Text>
      <ChevronDown size={16} />
      {/* @ts-ignore */}
      <Popper
        placement={"bottom-start"}
        referenceElement={referenceElement}
        visible={tooltipVisible}
        onClose={() => setTooltipVisible(false)}
        exceptions={[referenceElement]}
      >
        <MenuContainer minWidth={240}>
          <Menu
            // @ts-ignore
            items={periodMenuItems}
            selected={selected}
            onSelect={value => {
              setTooltipVisible(false);
              onSelect(value);
            }}
          />
        </MenuContainer>
      </Popper>
    </PeriodButton>
  );
};

export default PeriodSelect;
